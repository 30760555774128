// reference: https://gist.github.com/ahtcx/0cd94e62691f539160b32ecda18af3d6
// eslint-disable-next-line import/prefer-default-export
export const ARRAY_HANDLING_MERGE = "ARRAY_HANDLING_MERGE";
export const ARRAY_HANDLING_CONCAT = "ARRAY_HANDLING_CONCAT";
export const ARRAY_HANDLING_REPLACE = "ARRAY_HANDLING_REPLACE";
export const mergeDeep = (target, source, arrayMergeHandling) => {
    const sanitisedTarget = ((obj) => {
        let cloneObj;
        try {
            cloneObj = JSON.parse(JSON.stringify(obj));
        } catch (err) {
            // If the stringify fails due to circular reference, the merge defaults
            //   to a less-safe assignment that may still mutate elements in the target.
            // You can change this part to throw an error for a truly safe deep merge.
            cloneObj = { ...obj };
        }
        return cloneObj;
    })(target);

    const isObject = (obj) => obj && typeof obj === "object";

    if (!isObject(sanitisedTarget) || !isObject(source)) {
        return source;
    }

    Object.keys(source).forEach((key) => {
        const targetValue = sanitisedTarget[key];
        const sourceValue = source[key];

        if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
            switch (arrayMergeHandling) {
                case ARRAY_HANDLING_MERGE:
                    sanitisedTarget[key] = targetValue.map((x, i) =>
                        sourceValue.length <= i
                            ? x
                            : mergeDeep(x, sourceValue[i], arrayMergeHandling)
                    );
                    if (sourceValue.length > targetValue.length) {
                        sanitisedTarget[key] = sanitisedTarget[key].concat(
                            sourceValue.slice(targetValue.length)
                        );
                    }
                    break;
                case ARRAY_HANDLING_REPLACE:
                    sanitisedTarget[key] = sourceValue;
                    break;
                case ARRAY_HANDLING_CONCAT:
                default:
                    sanitisedTarget[key] = targetValue.concat(sourceValue);
                    break;
            }
        } else if (isObject(targetValue) && isObject(sourceValue)) {
            sanitisedTarget[key] = mergeDeep(
                { ...targetValue },
                sourceValue,
                arrayMergeHandling
            );
        } else {
            sanitisedTarget[key] = sourceValue;
        }
    });

    return sanitisedTarget;
};
