<template>
    <div :id="'horizonApp'">
        <GetSignCollections />
        <GetOccupancySettings />
        <WidgetInstance
            v-for="instance in instances"
            :key="instance.key"
            :widget-id="instance.widgetId"
            :widget-name="instance.widgetName"
            @destroy-widget="destroyWidgetInstance"
        />
        <ModalManager
            v-if="modal.show"
            :type="modal.data.modalType"
            :modal-customisation="modal.data"
            @modal-closed="handleModalClosed"
        />
    </div>
</template>

<script>
import GetSignCollections from "./accessors/SignCollections/GetSignCollections.vue";
import GetOccupancySettings from "./accessors/UserSettings/GetOccupancySettings.vue";
import WidgetInstance from "./widgets/WidgetInstance.vue";
import * as WidgetEvents from "../constants/widgetEvents";
import * as HorizonEvents from "../constants/horizonEvents";
import * as HorizonTasks from "../constants/horizonTasks";
import * as ModalTypes from "../constants/modalTypes";
import widgetStoreModule from "../store/modules/widgetStore";
import widgetStateMachine from "../stateMachines/widgetStateMachine";
import ModalManager from "./modals/ModalManager.vue";

export default {
    name: "HorizonApp",
    components: {
        GetOccupancySettings,
        GetSignCollections,
        WidgetInstance,
        ModalManager,
    },
    data() {
        return {
            instances: [],
            modal: {
                id: null,
                show: false,
                data: null,
            },
        };
    },
    computed: {
        runQueueTask() {
            return this.$store.getters.getFirstTaskInQueue;
        },
    },
    watch: {
        runQueueTask(task) {
            if (task === null) {
                return;
            }

            switch (task.type) {
                case HorizonTasks.WIDGET_ADD:
                    this.loadWidget(task.data);
                    this.$store.commit("sendEvent", {
                        type: HorizonEvents.QUEUE_TASK_COMPLETE,
                        taskId: task.id,
                    });
                    break;
                case HorizonTasks.WIDGET_REMOVE:
                    this.destroyWidgetInstance(task.widgetId);
                    this.$store.commit("sendEvent", {
                        type: HorizonEvents.QUEUE_TASK_COMPLETE,
                        taskId: task.id,
                    });
                    break;
                case HorizonTasks.SHOW_MODAL:
                    if (this.modal.show) {
                        return;
                    }
                    this.modal.id = task.id;
                    this.modal.data = task.data;
                    this.modal.show = true;
                    break;
                case HorizonTasks.SHOW_HELP:
                    this.$store.commit("sendEvent", {
                        type: HorizonEvents.HELP_OPEN,
                        helpUrl: task.helpUrl,
                    });
                    this.$store.commit("sendEvent", {
                        type: HorizonEvents.QUEUE_TASK_COMPLETE,
                        taskId: task.id,
                    });
                    break;
                case HorizonTasks.POPUP_WIDGET_LAUNCH:
                    this.$store.commit("sendEvent", {
                        type: HorizonEvents.POPUP_WIDGET_OPEN,
                        key: task.key,
                    });
                    this.$store.commit("sendEvent", {
                        type: HorizonEvents.QUEUE_TASK_COMPLETE,
                        taskId: task.id,
                    });
                    break;
                default:
                    break;
            }
        },
        modal: {
            immediate: true,
            deep: true,
            handler(newValue) {
                this.overLayTriggered(newValue.show);
            },
        },
    },
    mounted() {
        this.$store.commit("sendEvent", HorizonEvents.HAS_MOUNTED);
    },
    methods: {
        loadWidget({ widgetId, widgetName, containerData }) {
            // Register the widget specific store and construct

            // If new widget
            if (!this.instances.some((x) => x.widgetId === widgetId)) {
                this.addNewWidgetInstance(widgetId, widgetName, containerData);
                return;
            }

            // Widget already exists but needs to be loaded on the page

            const widgetContentPlaceholder = document.getElementById(
                `WidgetDisplayRoot${widgetId}`
            );
            if (widgetContentPlaceholder) {
                // ASCX Placeholder element is present
                const thisWidgetWasMaximised = document.querySelector(
                    `#widget_area_wrapper > #Widget${widgetId}_WidgetPanel`
                );
                if (thisWidgetWasMaximised) {
                    const widgetInstancePlaceholder = document.querySelector(
                        `*:not(#widget_area_wrapper) > #Widget${widgetId}_WidgetPanel`
                    );
                    if (widgetInstancePlaceholder) {
                        // delete the widget instance in the zone as it has been maximised
                        widgetInstancePlaceholder.remove();
                    }
                    return;
                }

                this.refreshWidgetInstance(widgetId, containerData, false);
                return;
            }

            // widget already mounted onto page and just needs a refresh
            this.refreshWidgetInstance(widgetId, containerData, true);
        },
        addNewWidgetInstance(widgetId, widgetName, containerData) {
            this.$store.registerModule(
                `widget${widgetId}`,
                widgetStoreModule.widgetStoreConstructor()
            );
            this.$store.commit(
                `widget${widgetId}/initialiseStateMachine`,
                widgetStateMachine.widgetStateMachineConstructor(
                    widgetId,
                    widgetName,
                    containerData
                )
            );

            const renderInstance = 0; // Just used to ensure the key is unique
            this.instances.push({
                widgetId,
                widgetName,
                renderInstance,
                key: `${widgetId}_${renderInstance}`,
            });
        },
        refreshWidgetInstance(widgetId, containerData, isMounted) {
            this.$store.commit(`widget${widgetId}/sendEvent`, {
                type: WidgetEvents.REFRESH_TRIGGERED,
                containerData,
                alreadyMounted: isMounted,
            });

            if (isMounted) {
                return;
            }

            // Update the key of the existing widgetInstance (this forces a re-render of the widget)
            this.instances = this.instances.map((x) =>
                x.widgetId === widgetId
                    ? {
                          ...x,
                          renderInstance: x.renderInstance + 1,
                          key: `${widgetId}_${x.renderInstance + 1}`,
                      }
                    : x
            );
        },
        destroyWidgetInstance(widgetId) {
            // remove the WidgetId from the array of Widgets to display
            this.instances = this.instances.filter((item) => {
                return item.widgetId !== widgetId;
            });
        },
        handleModalClosed(modalData) {
            this.modal.show = false;
            try {
                if (typeof modalData === "undefined") {
                    // Do nothing, modal closed remotely so trust whatever closed it to figure it out
                } else if (typeof this.modal.data.callback === "function") {
                    const { callback, ...dataToReturn } = modalData;
                    this.modal.data.callback(dataToReturn);
                } else if (typeof this.modal.data.widgetId !== "undefined") {
                    // Only set modal result if there is a widgetId waiting for it
                    this.$store.commit(
                        `widget${this.modal.data.widgetId}/sendEvent`,
                        {
                            type: WidgetEvents.SET_MODAL_RESULT,
                            modalResult: {
                                modalType: this.modal.data.modalType,
                                ...modalData,
                            },
                        }
                    );
                } else {
                    // Platform level modal so handle the result here
                    switch (modalData.modalType) {
                        case ModalTypes.DELETE_TAB:
                            if (modalData.modal.result) {
                                __doPostBack("DeleteTabLinkButton", "");
                            } else {
                                PlatformUI.Actions.hideSettingsPanel();
                            }
                            break;
                        case ModalTypes.DOCK_WIDGET:
                            if (modalData.modal.result) {
                                PopupWidget.hide();
                                WebForm_DoPostBackWithOptions(
                                    new WebForm_PostBackOptions(
                                        modalData.dockingWidgetId,
                                        modalData.modal.location,
                                        true,
                                        "",
                                        "",
                                        false,
                                        true
                                    )
                                );
                            }
                            break;
                        default:
                            break;
                    }
                }
            } catch (ex) {
                this.$store.commit("sendEvent", {
                    type: HorizonEvents.LOG,
                    data: { text: ex.message },
                });
            } finally {
                this.$store.commit("sendEvent", {
                    type: HorizonEvents.QUEUE_TASK_COMPLETE,
                    taskId: this.modal.id,
                });

                this.modal.data = null;
                this.modal.id = null;
            }
        },
        addTaskToHorizonQueue(event, task) {
            this.$store.commit("sendEvent", {
                type: event,
                task,
            });
        },
        sendEventToWidget(event, widgetId, data) {
            this.$store.commit(`widget${widgetId}/sendEvent`, {
                type: event,
                data,
            });
        },
        forceReloadAllWidgets() {
            this.instances.forEach((i) =>
                this.$store.commit(
                    `widget${i.widgetId}/sendEvent`,
                    WidgetEvents.FORCE_RELOAD
                )
            );
        },
        raiseEvent(eventDetails) {
            this.instances.forEach((i) =>
                this.$store.commit(`widget${i.widgetId}/sendEvent`, {
                    type: WidgetEvents.HORIZON_EVENT_RAISED,
                    data: eventDetails,
                })
            );
        },
        overLayTriggered(isOverlayShowing) {
            this.instances.forEach((i) =>
                this.$store.commit(`widget${i.widgetId}/sendEvent`, {
                    type: isOverlayShowing
                        ? WidgetEvents.HORIZON_OVERLAY_SHOWN
                        : WidgetEvents.HORIZON_OVERLAY_HIDDEN,
                })
            );
        },
    },
};
</script>
