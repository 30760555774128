import Vue from "vue";
import VueResize from "vue-resize";
import VueI18n from "vue-i18n";
import HorizonApp from "./components/HorizonApp.vue";
import horizonStateMachine from "./stateMachines/horizonStateMachine";
import * as HorizonEvents from "./constants/horizonEvents";
import * as HorizonTasks from "./constants/horizonTasks";
import * as WidgetEvents from "./constants/widgetEvents";
import store from "./store";

// Horizon helper object providing access to functions on the root Horizon Vue Component

Vue.use(VueResize);
Vue.use(VueI18n);

function Horizon() {
    let horizonApp;
    this.setup = (horizonData) => {
        const hData = JSON.parse(horizonData);
        const htmlElement = document.documentElement;
        htmlElement.setAttribute("theme", hData.theme);
        const i18n = new VueI18n({
            locale: hData.languageCode,
        });

        // initialise the state machine to the store
        store.commit(
            "initialiseStateMachine",
            horizonStateMachine.horizonStateMachineConstructor(hData)
        );

        // Create the Horizon Vue App and mount it in the Horizon page
        const HorizonConstructor = Vue.extend(HorizonApp);

        horizonApp = new HorizonConstructor({ i18n, store }).$mount(
            "#horizonApp"
        );
    };
    this.updateHorizonData = (horizonData) => {
        store.commit("sendEvent", {
            type: HorizonTasks.UPDATE_DATA,
            horizonData: JSON.parse(horizonData),
        });
    };
    // Public functions used to communicate between the "Horizon" horizonApp Vue instance
    this.loadWidget = (widgetId, widgetName, containerData) => {
        horizonApp.addTaskToHorizonQueue(HorizonEvents.QUEUE_ADD, {
            type: HorizonTasks.WIDGET_ADD,
            data: {
                widgetId,
                widgetName,
                containerData,
            },
        });
    };
    this.showModal = (modalData) => {
        horizonApp.addTaskToHorizonQueue(HorizonEvents.QUEUE_ADD, {
            type: HorizonTasks.SHOW_MODAL,
            data: modalData,
        });
    };
    this.hideModal = () => {
        // Remote call to hide current modal - trust whatever calls this to handle the impacts
        horizonApp.handleModalClosed();
    };
    this.widgetAscxHasLoaded = (widgetId, widgetData) => {
        horizonApp.sendEventToWidget(
            WidgetEvents.WIDGET_ASCX_LOADED,
            widgetId,
            widgetData
        );
    };
    this.horizonTriggeredUpdate = (widgetId) => {
        horizonApp.sendEventToWidget(
            WidgetEvents.UPDATE_REQUEST_FROM_HORIZON,
            widgetId
        );
    };
    this.widgetNoLongerMaximised = (widgetId) => {
        horizonApp.sendEventToWidget(
            WidgetEvents.DISPLAY_ANOTHER_WIDGET_WAS_MAXIMISED,
            widgetId
        );
    };
    this.overLayTriggered = (isOverlayShowing) => {
        horizonApp.overLayTriggered(isOverlayShowing);
    };
    this.forceReloadAllWidgets = () => {
        horizonApp.forceReloadAllWidgets();
    };
    this.raiseEvent = (eventDetails) => {
        horizonApp.raiseEvent(eventDetails);
    };

    // helper functions to monitor when Horizon is showing various overlays
    // Some use visibility: hidden, some use display: none which is why we need one of each
    this.respondToVisibility = (selector, callback, removeCondition) => {
        const options = {
            root: document.documentElement,
        };
        const element = document.querySelector(selector);

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                const isVisible = entry.intersectionRatio > 0;
                if (isVisible === removeCondition) {
                    observer.unobserve(entry.target);
                }
                callback(isVisible);
            });
        }, options);
        observer.observe(element);
    };
    this.addVisibilityObserver = (selector, removeIf) => {
        this.respondToVisibility(
            selector,
            (visible) => {
                this.overLayTriggered(visible);
            },
            removeIf
        );
    };
    this.respondToDisplayNone = (selector, callback) => {
        const element = document.querySelector(selector);
        const observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                if (mutation.attributeName === "style") {
                    callback(
                        window
                            .getComputedStyle(element)
                            .getPropertyValue("display") !== "none"
                    );
                }
            });
        });
        observer.observe(element, { attributes: true });
    };
    this.addDisplayNoneObserver = (selector) => {
        this.respondToDisplayNone(selector, this.overLayTriggered);
    };
}

export default {
    Horizon,
};
