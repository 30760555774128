<template>
    <transition name="modal">
        <div class="modal-mask">
            <div
                class="modal-wrapper"
                role="dialog"
                aria-labelledby="modalTitle"
                aria-describedby="modalDescription"
            >
                <div
                    class="modal-container"
                    :class="[{ 'modal-default-style': useDefaultStyle }]"
                >
                    <component
                        :is="modalComponentFromType"
                        :is-valid="isValid"
                        :validation-messages="validationMessages"
                        :modal-customisation="modalCustomisation"
                        v-on="$listeners"
                    >
                        <template #header>
                            <h3>
                                {{
                                    hasCustomHeader
                                        ? modalCustomisation.text.header
                                        : $t(`${type}.header`)
                                }}
                            </h3>
                        </template>

                        <template #body>
                            <span>{{
                                hasCustomBody
                                    ? modalCustomisation.text.body
                                    : $t(`${type}.body`)
                            }}</span>
                        </template>

                        <template #serverValidationResponse>
                            <span
                                v-if="
                                    isValid === false &&
                                    hasServerValidationResponseMessage
                                "
                                class="validation-error-text"
                                >{{
                                    modalCustomisation.validation
                                        .serverValidation.messageToDisplay
                                }}</span
                            >
                        </template>
                    </component>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import * as ModalTypes from "../../constants/modalTypes";
import EnglishStrings from "../../localisation/modals.en.json";
import FrenchStrings from "../../localisation/modals.fr.json";

// Only use inside ModalManager
export default {
    name: "ModalWrapper",
    components: {
        ModalOkCancel: () => import("./content/ModalOkCancel.vue"),
        ModalUserInput: () => import("./content/ModalUserInput.vue"),
        ModalDockWidget: () => import("./content/ModalDockWidget.vue"),
        ModalHostElement: () => import("./content/ModalHostElement.vue"),
    },
    props: {
        type: {
            type: String,
            required: true,
        },
        modalCustomisation: {
            type: Object,
            required: true,
        },
        isValid: {
            type: Boolean,
        },
        validationMessages: {
            type: Array,
            required: true,
        },
    },
    computed: {
        modalComponentFromType() {
            switch (this.type) {
                case ModalTypes.DOCK_WIDGET:
                    return "ModalDockWidget";
                case ModalTypes.USER_INPUT:
                    return "ModalUserInput";
                case ModalTypes.HOST_ELEMENT:
                    return "ModalHostElement";
                case ModalTypes.DELETE_WIDGET:
                case ModalTypes.RESET_SETTINGS:
                case ModalTypes.CUSTOM_OK_CANCEL:
                default:
                    return "ModalOkCancel";
            }
        },
        hasCustomText() {
            return this.modalCustomisation.text !== undefined;
        },
        hasCustomHeader() {
            return (
                this.hasCustomText &&
                this.modalCustomisation.text.header !== null
            );
        },
        hasCustomBody() {
            return (
                this.hasCustomText && this.modalCustomisation.text.body !== null
            );
        },
        hasValidationData() {
            return typeof this.modalCustomisation.validation !== "undefined";
        },
        hasServerValidationResponse() {
            return (
                this.hasValidationData &&
                typeof this.modalCustomisation.validation.serverValidation !==
                    "undefined"
            );
        },
        hasServerValidationResponseMessage() {
            return (
                this.hasServerValidationResponse &&
                typeof this.modalCustomisation.validation.serverValidation
                    .messageToDisplay === "string"
            );
        },
        useDefaultStyle() {
            return this.modalCustomisation.useDefaultStyle !== false;
        },
    },
    i18n: {
        messages: {
            en: EnglishStrings,
            fr: FrenchStrings,
        },
    },
};
</script>

<style lang="scss" scoped>
.modal-mask {
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    transition: opacity 0.3s ease;
    width: 100%;
    z-index: 10000;
}

.modal-wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
}
.modal-container {
    background-color: var(--colour-site-background-one);
    border-radius: 2px;
    box-shadow: 0 2px 8px var(--colour-thickbox-box-shadow);
    color: var(--colour-thickbox-window-text);
    font-family: Helvetica, Arial, sans-serif;
    margin: 0px auto;

    transition: all 0.3s ease;
}

.modal-default-style {
    border: 4px solid var(--colour-thickbox-window-border);
    padding: 20px 30px;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
</style>
