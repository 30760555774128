var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "vue-widget-content",
      class: { "vue-widget-content-resize": _vm.canResize },
      style: `height:${_vm.contentHeightInit}; min-height:${_vm.contentHeightMin};`,
      attrs: { id: "widgetContentWrapper" + _vm.widgetId },
    },
    [
      _vm.inError
        ? _c(
            "div",
            { staticClass: "vue-widget-content-error-message-wrapper" },
            [_c("Error", { attrs: { "error-type": _vm.errorType } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.inError
        ? _c(
            "div",
            {
              staticClass: "vue-widget-content-wrapper",
              attrs: { id: "widgetContent" + _vm.widgetId + _vm.widgetName },
            },
            [
              _c(
                _vm.widgetName,
                _vm._g(
                  { tag: "component", attrs: { "widget-id": _vm.widgetId } },
                  _vm.$listeners
                )
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("ResizeObserver", {
        attrs: { "emit-on-mount": true },
        on: { notify: _vm.handleResize },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }