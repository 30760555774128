export const ERROR = "ERROR";
export const WARNING = "WARNING";
export const INFO = "INFO";
export const MISC = "MISC";

export const LogLevelColours = {
    MISC: "background: #222222; color: #ffffff",
    ERROR: "background: #222222; color: #ff0000",
    WARNING: "background: #222222; color: #c4c704",
    INFO: "background: #222222; color: #0390fc",
};
