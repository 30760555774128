var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "vue-widget-header-text vue-widget-header-drag" },
    [
      _vm.isEditing
        ? _c("div", { staticClass: "vue-widget-header-text-edit-font-size" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.newDisplayName,
                  expression: "newDisplayName",
                },
              ],
              staticClass: "input-width-widget-name",
              domProps: { value: _vm.newDisplayName },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.newDisplayName = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "vue-widget-button-highlight vue-widget-header-button-obfuscate",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.handleSaveNewName.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n            " + _vm._s(_vm.$t("save")) + "\n        ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "vue-widget-button-highlight vue-widget-header-button-obfuscate",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    _vm.isEditing = false
                  },
                },
              },
              [
                _vm._v(
                  "\n            " + _vm._s(_vm.$t("cancel")) + "\n        "
                ),
              ]
            ),
          ])
        : _c("div", [
            _c(
              "button",
              {
                staticClass:
                  "vue-widget-button-highlight vue-widget-header-button-obfuscate",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    _vm.isEditing = true
                  },
                },
              },
              [
                _vm._v(
                  "\n            " + _vm._s(_vm.displayName) + "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showDataConnection,
                    expression: "showDataConnection",
                  },
                ],
                staticClass: "vue-widget-data-connection",
              },
              [
                _vm._v(
                  "\n            - " +
                    _vm._s(_vm.getDataConnectionDisplayText) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isPaused,
                    expression: "isPaused",
                  },
                ],
                staticClass: "vue-widget-time-paused",
              },
              [
                _vm._v(
                  "\n            (" + _vm._s(_vm.pauseTime) + ")\n        "
                ),
              ]
            ),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }