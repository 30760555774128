<template>
    <div class="vue-widget-prevent-interaction">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "DisableInteraction",
};
</script>

<style lang="scss" scoped>
.vue-widget-prevent-interaction {
    background-color: var(--vue-disable-background);
    display: grid;
    height: 100%;
    position: absolute;
    transition: opacity 0.3s ease;
    width: 100%;
    z-index: 100;
}
</style>
