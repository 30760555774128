var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("GetUserSettings", {
    attrs: { get: _vm.get, group: _vm.group },
    on: { "settings-loaded": _vm.handleSettingsLoaded, error: _vm.handleError },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }