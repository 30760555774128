<template>
    <div></div>
</template>

<script>
import * as HorizonEvents from "../../../constants/horizonEvents";
import * as DataLoadingStates from "../../../constants/dataLoadingStates";

export default {
    name: "GetSignCollections",
    computed: {
        isLoadSignCollectionsRequired() {
            return (
                this.$store.state.context.signCollectionsLoadState ===
                DataLoadingStates.LOAD
            );
        },
    },
    watch: {
        isLoadSignCollectionsRequired: {
            immediate: true,
            handler: function (isLoadSignCollectionsRequired) {
                if (!isLoadSignCollectionsRequired) {
                    return;
                }
                AcisHorizon.Web.Framework.WidgetService.GetSignCollections(
                    this.onGetSignCollectionsSuccess,
                    this.onGetSignCollectionsFailure
                );
            },
        },
    },
    methods: {
        onGetSignCollectionsSuccess(signCollections) {
            this.$store.commit("sendEvent", {
                type: HorizonEvents.SIGN_COLLECTIONS_SET_CONTEXT,
                signCollections: signCollections,
            });
        },
        onGetSignCollectionsFailure() {
            this.$store.commit(
                "sendEvent",
                HorizonEvents.SIGN_COLLECTIONS_ERROR
            );
        },
    },
};
</script>
