<template>
    <div></div>
</template>

<script>
import * as X2j from "xml-js";
import * as HorizonUserSettingsElementGroups from "../../../constants/horizonUserStateGroups";

export default {
    name: "GetUserSettings",
    props: {
        get: {
            type: Boolean,
            required: true,
        },
        group: {
            type: [String],
            required: true,
            validator: function (value) {
                return (
                    [
                        HorizonUserSettingsElementGroups.OCCUPANCY_MANAGER_SETTINGS,
                    ].indexOf(value) !== -1
                );
            },
        },
    },
    emits: ["error", "settings-loaded"],
    watch: {
        get: {
            immediate: true,
            handler: function (get) {
                if (!get) {
                    return;
                }
                AcisHorizon.Web.Framework.WidgetService.GetUserSettings(
                    this.group,
                    this.onGetUserSettingsSuccess,
                    this.onGetUserSettingsFailure
                );
            },
        },
    },
    methods: {
        onGetUserSettingsSuccess(settingsXml) {
            try {
                this.$emit(
                    "settings-loaded",
                    X2j.xml2js(settingsXml, {
                        compact: true,
                        ignoreComment: true,
                        alwaysChildren: true,
                    }).state
                );
            } catch (e) {
                this.$emit("error");
            }
        },
        onGetUserSettingsFailure() {
            this.$emit("error");
        },
    },
};
</script>
