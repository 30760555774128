export const MINIMISED = "MINIMISED";
export const MINIMISING = "MINIMISING";

export const MAXIMISED = "MAXIMISED";
export const MAXIMISING = "MAXIMISING";
export const REVERTING_FROM_MAX_FROM_WIDGET_TRIGGER =
    "REVERTING_FROM_MAX_FROM_WIDGET_TRIGGER";
export const REVERTING_FROM_MAX_FROM_HORIZON_TRIGGER =
    "REVERTING_FROM_MAX_FROM_HORIZON_TRIGGER";

export const NORMAL = "NORMAL";
