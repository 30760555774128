export const VIEW_DEBUG = "VIEW_DEBUG";
export const WIDGET_PRINT = "WIDGET_PRINT";
export const WIDGET_UNDOCK = "WIDGET_UNDOCK";
export const WIDGET_FREEZE = "WIDGET_FREEZE";
export const VEHICLE_USER_ACTIONED = "VEHICLE_USER_ACTIONED";
export const VEHICLE_DRIVER_NUMBER = "VEHICLE_DRIVER_NUMBER";
export const VEHICLE_STATUS_MESSAGE = "VEHICLE_STATUS_MESSAGE";
export const VEHICLE_TYPE = "VEHICLE_TYPE";
export const VEHICLE_DETAIL_DRIVER_MESSAGING =
    "VEHICLE_DETAIL_DRIVER_MESSAGING";
export const VEHICLE_DETAIL_OVD_MESSAGING = "VEHICLE_DETAIL_OVD_MESSAGING";
export const DISPLAY_FOOTER_WIDGET = "DISPLAY_FOOTER_WIDGET";
export const EVENTS_AUDIO = "EVENTS_AUDIO";
export const EVENTS_PANIC = "EVENTS_PANIC";
export const EVENTS_EARLY = "EVENTS_EARLY";
export const EVENTS_HEADWAY_DEVIATION = "EVENTS_HEADWAY_DEVIATION";
export const EVENTS_DRIVER_MESSAGE_RECEIVED = "EVENTS_DRIVER_MESSAGE_RECEIVED";
export const EVENTS_JOURNEY_CANCELLATION = "EVENTS_JOURNEY_CANCELLATION";
export const EVENTS_THIRD_PARTY_JOURNEY_CANCELLATION = "EVENTS_THIRD_PARTY_JOURNEY_CANCELLATION";
export const EVENTS_DRIVER_MESSAGE_SENT = "EVENTS_DRIVER_MESSAGE_SENT";
export const EVENTS_OVD_MESSAGE_SENT = "EVENTS_OVD_MESSAGE_SENT";
export const WIDGET_EXPORT = "WIDGET_EXPORT";
export const EVENTS_MULTI_JOURNEY_CANCELLATION =
    "EVENTS_MULTI_JOURNEY_CANCELLATION";
export const EVENTS_DEVICE_FAULT = "EVENTS_DEVICE_FAULT";
export const EVENTS_OFF_ROUTE = "EVENTS_OFF_ROUTE";
export const EVENTS_FULL = "EVENTS_FULL";
export const VEHICLE_DETAIL_GUEST_MESSAGING = "VEHICLE_DETAIL_GUEST_MESSAGING";
export const VEHICLE_ETM_REVENUE_CODE = "VEHICLE_ETM_REVENUE_CODE";
export const VEHICLE_ETM_RUNNING_BOARD = "VEHICLE_ETM_RUNNING_BOARD";
export const VEHICLE_PANICS_AND_FAULTS = "VEHICLE_PANICS_AND_FAULTS";
export const DISPLAY_VEHICLE_HISTORY = "DISPLAY_VEHICLE_HISTORY";
export const DRIVER_MESSAGING_FREE_TEXT = "DRIVER_MESSAGING_FREE_TEXT";
export const DATA_VISIBLE_SIGN = "DATA_VISIBLE_SIGN";
export const CONFIGURE_STAND_REALLOCATIONS = "CONFIGURE_STAND_REALLOCATIONS";
export const IMPORT_PREDEFINED_MESSAGES = "IMPORT_PREDEFINED_MESSAGES";
export const SIGN_MESSAGING_FREE_TEXT = "SIGN_MESSAGING_FREE_TEXT";
export const SIGN_MESSAGING_PARAMETERS = "SIGN_MESSAGING_PARAMETERS";
export const STOP_FILTER = "STOP_FILTER";
export const PSM_MESSAGES_SETTINGS = "PSM_MESSAGES_SETTINGS";
export const EVENTS_INVALID_JOURNEY = "EVENTS_INVALID_JOURNEY";
export const EVENTS_DRIVER_SIGN_IN_MAY_BE_INCORRECT =
    "EVENTS_DRIVER_SIGN_IN_MAY_BE_INCORRECT";
export const IMPORT_PREDEFINED_VEHICLE_MESSAGES =
    "IMPORT_PREDEFINED_VEHICLE_MESSAGES";
export const STOP_UNRESTRICTED_MESSAGE_SIGN_GROUPS =
    "STOP_UNRESTRICTED_MESSAGE_SIGN_GROUPS";
export const VEHICLE_DETAIL_REPLACEMENT_VEHICLE =
    "VEHICLE_DETAIL_REPLACEMENT_VEHICLE";
export const EVENTS_VEHICLE_REPLACEMENT = "EVENTS_VEHICLE_REPLACEMENT";
export const VOICE_CALL = "VOICE_CALL";
export const AUDIO_MESSAGE = "AUDIO_MESSAGE";
export const VOICE_CALL_HISTORY = "VOICE_CALL_HISTORY";
export const IMPORT_VEHICLE_PHONE_NUMBERS = "IMPORT_VEHICLE_PHONE_NUMBERS";
export const EVENTS_DRIVER_MESSAGE_AUDIO_SENT =
    "EVENTS_DRIVER_MESSAGE_AUDIO_SENT";
export const EVENTS_PASSENGER_MESSAGE_AUDIO_SENT =
    "EVENTS_PASSENGER_MESSAGE_AUDIO_SENT";
export const EVENTS_CALL_REQUEST = "EVENTS_CALL_REQUEST";
export const EVENTS_EMERGENCY_CALL_REQUEST = "EVENTS_EMERGENCY_CALL_REQUEST";
export const VOICE_CALL_RECORDING = "VOICE_CALL_RECORDING";
export const DISPLAY_SIGN_MODE = "DISPLAY_SIGN_MODE";
export const IMPORT_JOURNEY_CANCELLATION_REASONS =
    "IMPORT_JOURNEY_CANCELLATION_REASONS";
export const DRIVER_AUDIO_MESSAGING = "DRIVER_AUDIO_MESSAGING";
export const PASSENGER_AUDIO_MESSAGING = "PASSENGER_AUDIO_MESSAGING";
export const UNCHECKED_SYSTEM_MESSAGES = "UNCHECKED_SYSTEM_MESSAGES";
export const NOTIFICATION_MANAGEMENT = "NOTIFICATION_MANAGEMENT";
export const CAN_CREATE_MESSAGE_TEMPLATE_CATEGORIES =
    "CAN_CREATE_MESSAGE_TEMPLATE_CATEGORIES";
export const ADVISOR_GLOBAL_ACKNOWLEDGE = "ADVISOR_GLOBAL_ACKNOWLEDGE";
export const ADVISOR_GLOBAL_DISMISS = "ADVISOR_GLOBAL_DISMISS";
export const SIGN_MESSAGING_CAN_BYPASS_SERVICED_BY_REQUIREMENT =
    "SIGN_MESSAGING_CAN_BYPASS_SERVICED_BY_REQUIREMENT";
