<template>
    <div :class="isValid ? false : 'errors'">
        <ModalWrapper
            :type="type"
            :modal-customisation="modalCustomisation"
            :is-valid="isValid"
            :validation-messages="validationMessages"
            @modal-closed="handleModalClosed"
            @validation-pass="handleValidationPass"
            @validation-fail="handleValidationFail"
        />
    </div>
</template>

<script>
import * as ModalTypes from "../../constants/modalTypes";
import ModalWrapper from "./ModalWrapper.vue";

export default {
    name: "ModalManager",
    components: {
        ModalWrapper,
    },
    props: {
        type: {
            type: String,
            required: true,
            validator(value) {
                return (
                    [
                        ModalTypes.CUSTOM_OK_CANCEL,
                        ModalTypes.DELETE_WIDGET,
                        ModalTypes.DELETE_TAB,
                        ModalTypes.RESET_SETTINGS,
                        ModalTypes.USER_INPUT,
                        ModalTypes.DOCK_WIDGET,
                        ModalTypes.HOST_ELEMENT,
                    ].indexOf(value) !== -1
                );
            },
        },
        modalCustomisation: {
            type: Object,
            required: true,
            validator(v) {
                return (
                    (typeof v.callback === "undefined" ||
                        typeof v.callback === "function") &&
                    (typeof v.text === "undefined" ||
                        ((typeof v.text.header === "undefined" ||
                            typeof v.text.header === "string") &&
                            (typeof v.text.body === "undefined" ||
                                typeof v.text.body === "string"))) &&
                    (typeof v.validation === "undefined" ||
                        ((typeof v.validation.maxLength === "undefined" ||
                            typeof v.validation.maxLength === "number") &&
                            (typeof v.validation.serverValidation ===
                                "undefined" ||
                                (typeof v.validation.serverValidation
                                    .isValid === "boolean" &&
                                    (typeof v.validation.serverValidation
                                        .messageToDisplay === "undefined" ||
                                        typeof v.validation.serverValidation
                                            .messageToDisplay === "string")))))
                );
            },
        },
    },
    emits: ["modal-closed"],
    data() {
        return {
            isValid: null,
            validationMessages: [],
        };
    },
    computed: {
        hasValidationData() {
            return typeof this.modalCustomisation.validation !== "undefined";
        },
        hasServerValidationResponse() {
            return (
                this.hasValidationData &&
                typeof this.modalCustomisation.validation.serverValidation !==
                    "undefined"
            );
        },
    },
    created() {
        if (this.hasValidationData) {
            this.isValid = this.hasServerValidationResponse
                ? this.modalCustomisation.validation.serverValidation.isValid
                : true;
        }
    },
    methods: {
        handleModalClosed(modal) {
            let returnData;
            if (this.modalCustomisation === null) {
                returnData = { modal };
            } else {
                const { text, ...returnObject } = this.modalCustomisation;
                returnData = {
                    ...returnObject,
                    modal,
                };
            }
            this.$emit("modal-closed", returnData);
        },
        handleValidationPass() {
            this.isValid = true;
            this.validationMessages = [];
        },
        handleValidationFail(message) {
            this.isValid = false;
            if (typeof message === "string") {
                this.validationMessages.push(message);
            }
        },
    },
};
</script>

<style lang="scss">
.validation-error-text {
    color: var(--text-error);
    font-weight: bold;
}
</style>
<style lang="scss" scoped>
div.errors form {
    :invalid {
        outline: 2px solid var(--text-error);
    }
}
</style>
