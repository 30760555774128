import { createMachine, assign, sendParent } from "xstate";
import * as WidgetSettingsStates from "../constants/widgetSettingsStates";
import * as WidgetEvents from "../constants/widgetEvents";

export default {
    widgetSettingsStateMachineConstructor(widgetId) {
        return createMachine(
            {
                id: "display",
                context: {
                    widgetId,

                    settingsComponents: [],
                },
                initial: WidgetSettingsStates.CLOSED,
                on: {
                    HAS_CREATED: {
                        actions: assign({
                            settingsComponents: (context, event) => {
                                if (typeof event.settingsIds === "undefined") {
                                    return [];
                                }
                                // Only register components if they haven't already been registered
                                if (context.settingsComponents.length > 0) {
                                    return context.settingsComponents;
                                }

                                return event.settingsIds.map((x) => {
                                    return {
                                        id: x,
                                        isValid: null,
                                        isUnsavedValid: null,
                                        isChanged: false,
                                        isActioned: false,
                                        isDefault: true,
                                    };
                                });
                            },
                        }),
                    },
                    SETTINGS_MACHINE_OPENING_STATE: {
                        actions: "updateComponents",
                    },
                    SETTINGS_MACHINE_VALIDATION_RESULT: {
                        actions: [
                            assign({
                                settingsComponents: (context, event) => {
                                    const components =
                                        context.settingsComponents;
                                    const index = components.findIndex(
                                        (x) => x.id === event.id
                                    );
                                    if (index > -1) {
                                        components[index].isValid =
                                            event.isValid;
                                    }
                                    return components;
                                },
                            }),
                            sendParent(WidgetEvents.SETTINGS_VALIDATION_UPDATE), // Parent needs an event to trigger checking the validation state
                        ],
                    },
                    SETTINGS_MACHINE_ACTIONED: {
                        actions: assign({
                            settingsComponents: (context, event) => {
                                const components = context.settingsComponents;
                                const index = components.findIndex(
                                    (x) => x.id === event.id
                                );
                                if (index > -1) {
                                    components[index].isActioned = true;
                                }
                                return components;
                            },
                        }),
                    },
                },
                states: {
                    CLOSED: {
                        entry: sendParent(WidgetEvents.SETTINGS_CLOSE),
                        on: {
                            SETTINGS_MACHINE_OPEN: WidgetSettingsStates.OPENING,
                        },
                    },
                    OPENING: {
                        always: {
                            target: WidgetSettingsStates.OPEN,
                            cond: "allComponentsActioned",
                        },
                        exit: "resetIsActioned",
                    },
                    OPEN: {
                        on: {
                            SETTINGS_MACHINE_UPDATED: {
                                actions: "updateComponents",
                            },
                            SETTINGS_MACHINE_SAVE_TRIGGERED: {
                                target: WidgetSettingsStates.SAVING,
                            },
                            SETTINGS_MACHINE_CANCEL_TRIGGERED: {
                                target: WidgetSettingsStates.CLOSED,
                            },
                            SETTINGS_MACHINE_RESET_TRIGGERED: {
                                target: WidgetSettingsStates.RESETTING,
                            },
                        },
                    },
                    SAVING: {
                        always: {
                            target: WidgetSettingsStates.CLOSED,
                            cond: "allComponentsActioned",
                        },
                        exit: "resetIsActioned",
                    },
                    RESETTING: {
                        always: {
                            target: WidgetSettingsStates.CLOSED,
                            cond: "allComponentsActioned",
                        },
                        exit: [
                            "resetIsActioned",
                            sendParent(WidgetEvents.RESET_ACTIONED), // Legacy Settings approach support
                        ],
                    },
                },
            },
            {
                guards: {
                    allComponentsActioned: (context) => {
                        if (context.settingsComponents.length === 0) {
                            return false;
                        }
                        if (
                            context.settingsComponents === null ||
                            typeof context.settingsComponents === "undefined"
                        ) {
                            return false;
                        }
                        return context.settingsComponents.every(
                            (x) => x.isActioned
                        );
                    },
                },
                actions: {
                    resetIsActioned: assign({
                        settingsComponents: (context) => {
                            const components = context.settingsComponents.map(
                                (x) => {
                                    return { ...x, isActioned: false };
                                }
                            );
                            return components;
                        },
                    }),
                    updateComponents: assign({
                        settingsComponents: (context, event) => {
                            const components = context.settingsComponents;
                            const index = components.findIndex(
                                (x) => x.id === event.id
                            );
                            if (index > -1) {
                                components[index].isUnsavedValid =
                                    event.isUnsavedValid;
                                components[index].isChanged = event.isChanged;
                            }
                            if (typeof event.isDefault !== "undefined") {
                                components[index].isDefault = event.isDefault;
                            }
                            return components;
                        },
                    }),
                },
            }
        );
    },
};
