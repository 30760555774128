import { interpret } from "xstate";
import * as WidgetStates from "../../constants/widgetStates";
import * as LogLevels from "../../constants/logLevels";
import * as PageTypes from "../../constants/pageTypes";

const debug = process.env.NODE_ENV !== "production";

export default {
    widgetStoreConstructor() {
        return {
            namespaced: true,
            state() {
                return {
                    widgetStateMachineService: null,
                    currentState: null,
                    context: null,
                };
            },
            mutations: {
                sendEvent(state, event) {
                    state.widgetStateMachineService.send(event);
                },
                initialiseStateMachine(state, machine) {
                    state.widgetStateMachineService = interpret(machine)
                        .onTransition((newState) => {
                            if (debug) {
                                console.log(
                                    `%c${newState.context.widgetId}: %c${newState._event.name} %c| %c${state.currentState} %c-> %c${newState.value}`,
                                    LogLevels.LogLevelColours[LogLevels.MISC],
                                    LogLevels.LogLevelColours[
                                        LogLevels.WARNING
                                    ],
                                    LogLevels.LogLevelColours[LogLevels.MISC],
                                    LogLevels.LogLevelColours[LogLevels.INFO],
                                    LogLevels.LogLevelColours[LogLevels.MISC],
                                    LogLevels.LogLevelColours[LogLevels.WARNING]
                                );
                            }
                            state.currentState = newState.value;
                            state.context = newState.context;
                        })
                        .start();
                },
            },
            getters: {
                containerName: (state) => {
                    return state.context.containerData.displayName;
                },
                clientControlId: (state) => (clientControlName) => {
                    const control =
                        state.context.widgetData.clientControls.find(
                            (clientControl) =>
                                clientControl.name === clientControlName
                        );
                    return control === undefined ? null : control.id;
                },
                isBusy: (state) => {
                    if (
                        [
                            WidgetStates.PAUSED,
                            WidgetStates.ERRORED,
                            WidgetStates.SETTINGS_OPEN,
                        ].includes(state.currentState)
                    ) {
                        return false;
                    }
                    if (
                        [
                            WidgetStates.REFRESH,
                            WidgetStates.REFRESHING,
                            WidgetStates.RESTORE_DATA,
                        ].includes(state.currentState)
                    ) {
                        return true;
                    }
                    return !state.context.isHydrated;
                },
                isHydrated: (state) => {
                    return state.context.isHydrated;
                },
                canExport: (state) => {
                    return state.context.widgetData === null
                        ? false
                        : state.context.widgetData.canExport === true;
                },
                canPrint: (state) => {
                    return state.context.widgetData === null
                        ? false
                        : state.context.widgetData.canPrint === true;
                },
                canPause: (state) => {
                    return state.context.widgetData === null
                        ? false
                        : state.context.widgetData.canPauseUpdates === true;
                },
                hasSettings: (state) => {
                    return state.context.hasSettings;
                },
                helpUrl: (state) => {
                    return state.context.containerData.helpUrl;
                },
                modalResult: (state) => {
                    return state.context.modalResults.length > 0
                        ? state.context.modalResults[0]
                        : null;
                },
                userConnections: (state) => {
                    return state.context.widgetData.userConnections;
                },
                isToggleActive: (state) => (toggleId) => {
                    const tIndex = state.context.toggles.findIndex(
                        (x) => x.id === toggleId
                    );
                    return tIndex > -1
                        ? state.context.toggles[tIndex].state
                        : null;
                },
                toggles: (state) => {
                    return state.context.toggles;
                },
                pageType: (state) => {
                    const pageType =
                        state.context.containerData.location.pageType;
                    if (pageType === undefined) {
                        return PageTypes.HORIZON_BASIC;
                    }
                    return PageTypes.TRANSIENT;
                },
                heightInit: (state) => {
                    const pageType =
                        state.context.containerData.location.pageType;
                    if (pageType === PageTypes.TRANSIENT) {
                        return "100%";
                    }
                    return `${state.context.containerData.size.heightInit}px`;
                },
                heightMin: (state) => {
                    return `${state.context.containerData.size.heightMin}px`;
                },
                isInBackground: (state) => {
                    return state.context.isBehindObjects > 0;
                },
                canResize: (state, getters) => {
                    return (
                        state.context.containerData.location.pageType !==
                            PageTypes.TRANSIENT &&
                        state.context.containerData.size.canResize &&
                        !getters.isInBackground
                    );
                },
                instanceState: (state) => {
                    return state.context.instanceState;
                },
                instanceStateUserConnection: (state, getters) => {
                    const instanceState = getters.instanceState;
                    if (instanceState === null) {
                        return null;
                    }

                    if (
                        typeof instanceState.operator !== "undefined" &&
                        typeof instanceState.depot !== "undefined"
                    ) {
                        return {
                            operatorId: instanceState.operator,
                            depotId: instanceState.depot,
                        };
                    }
                    return null;
                },
                isSettingsValid: (state) => {
                    if (
                        state.context.settingsMachine.state.context
                            .settingsComponents.length === 0
                    ) {
                        return false;
                    }
                    if (
                        state.context.settingsMachine.state.context
                            .settingsComponents === null ||
                        typeof state.context.settingsMachine.state.context
                            .settingsComponents === "undefined"
                    ) {
                        return true;
                    }

                    return state.context.settingsMachine.state.context.settingsComponents.every(
                        (x) => x.isValid
                    );
                },
                canSaveSettings: (state) => {
                    if (
                        state.context.settingsMachine.state.context
                            .settingsComponents.length === 0
                    ) {
                        return false;
                    }
                    if (
                        state.context.settingsMachine.state.context
                            .settingsComponents === null ||
                        typeof state.context.settingsMachine.state.context
                            .settingsComponents === "undefined"
                    ) {
                        return true;
                    }

                    return (
                        state.context.settingsMachine.state.context.settingsComponents.every(
                            (x) => x.isUnsavedValid
                        ) &&
                        state.context.settingsMachine.state.context.settingsComponents.some(
                            (x) => x.isChanged
                        )
                    );
                },
                canResetSettings: (state) => {
                    if (
                        state.context.settingsMachine.state.context
                            .settingsComponents.length === 0
                    ) {
                        return false;
                    }
                    if (
                        state.context.settingsMachine.state.context
                            .settingsComponents === null ||
                        typeof state.context.settingsMachine.state.context
                            .settingsComponents === "undefined"
                    ) {
                        return false;
                    }

                    return state.context.settingsMachine.state.context.settingsComponents.some(
                        (x) => !x.isDefault
                    );
                },
                settingsMachineState: (state) => {
                    return state.context.settingsMachine.state.value;
                },
            },
        };
    },
};
