<template>
    <div
        :id="'widgetContentWrapper' + widgetId"
        :style="`height:${contentHeightInit}; min-height:${contentHeightMin};`"
        class="vue-widget-content"
        :class="{ 'vue-widget-content-resize': canResize }"
    >
        <div v-if="inError" class="vue-widget-content-error-message-wrapper">
            <Error :error-type="errorType" />
        </div>
        <div
            v-if="!inError"
            :id="'widgetContent' + widgetId + widgetName"
            class="vue-widget-content-wrapper"
        >
            <component
                :is="widgetName"
                :widget-id="widgetId"
                v-on="$listeners"
            ></component>
        </div>
        <ResizeObserver :emit-on-mount="true" @notify="handleResize" />
    </div>
</template>

<script>
import Error from "../utils/Error.vue";
import * as WidgetEvents from "../../constants/widgetEvents";
import * as WidgetStates from "../../constants/widgetStates";
import "vue-resize/dist/vue-resize.css";

export default {
    name: "WidgetContent",
    components: {
        Error,
        WidgetNotificationManagement: () =>
            import(
                "../../../../../../Widgets/WidgetNotificationManagement/Widgets/WidgetNotificationManagement/assets/scripts/WidgetNotificationManagement.vue"
            ),
        WidgetMessageTemplateList: () =>
            import(
                "../../../../../../Widgets/WidgetMessageTemplateList/Widgets/WidgetMessageTemplateList/assets/scripts/WidgetMessageTemplateList.vue"
            ),
        WidgetStandOccupancyManager: () =>
            import(
                "../../../../../../Widgets/WidgetStandOccupancyManager/Widgets/WidgetStandOccupancyManager/assets/scripts/WidgetStandOccupancyManager.vue"
            ),
        WidgetSystemAdvisor: () =>
            import(
                "../../../../../../Widgets/WidgetSystemAdvisor/Widgets/WidgetSystemAdvisor/assets/scripts/WidgetSystemAdvisor.vue"
            ),
        WidgetJourneyCancellations: () =>
            import(
                "../../../../../../Widgets/WidgetJourneyCancellations/Widgets/WidgetJourneyCancellations/assets/scripts/WidgetJourneyCancellations.vue"
            ),
    },
    props: {
        widgetId: {
            type: Number,
            required: true,
        },
        widgetName: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            resizeTimer: null,
        };
    },
    computed: {
        contentHeightInit() {
            return this.$store.getters[`widget${this.widgetId}/heightInit`];
        },
        contentHeightMin() {
            return this.$store.getters[`widget${this.widgetId}/heightMin`];
        },
        canResize() {
            return this.$store.getters[`widget${this.widgetId}/canResize`];
        },
        inError() {
            return (
                this.$store.state[`widget${this.widgetId}`].currentState ===
                WidgetStates.ERRORED
            );
        },
        errorType() {
            return this.$store.state[`widget${this.widgetId}`].context
                .errorType;
        },
        
    },
    methods: {
        handleResize({ width, height }) {
            if (height < this.contentHeightMin) {
                return;
            }
            clearTimeout(this.resizeTimer);
            this.resizeTimer = setTimeout(() => {
                // In a timeout to avoid firing constantly during resize
                this.$store.commit(`widget${this.widgetId}/sendEvent`, {
                    type: WidgetEvents.DISPLAY_RESIZED,
                    height,
                    width,
                });
            }, 250);
        },
    },
};
</script>

<style lang="scss" scoped>
.vue-widget-content {
    background-color: var(--skin-widget-background);
    display: flex;
    overflow: auto;
    position: relative;
}
.vue-widget-content-resize {
    resize: vertical;
}
.vue-widget-content-error-message-wrapper {
    display: grid;
    height: 100%;
    position: absolute;
    transition: opacity 0.3s ease;
    width: 100%;
}
.vue-widget-content-error-message {
    color: var(--vue-widget-error-highlight);
    font-size: 20px;
    margin: auto;
    padding: 10px;
    text-align: center;
    z-index: 101;
}
.vue-widget-content-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;
    div {
        flex: 1;
    }
}
</style>
