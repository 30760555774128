var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "vue-widget-debug",
      class: { "vue-widget-debug-error": _vm.inError },
      attrs: { id: "widgetDebug" + _vm.widgetId },
    },
    [
      _c("span", [
        _vm._v(
          _vm._s(_vm.widgetId) +
            ": " +
            _vm._s(_vm.platformState) +
            " | " +
            _vm._s(_vm.currentState) +
            " |\n        " +
            _vm._s(_vm.displayCurrentState) +
            " | " +
            _vm._s(_vm.settingsCurrentState)
        ),
      ]),
      _vm._v(" "),
      _vm.hasDebugMessages
        ? _c(
            "ul",
            _vm._l(_vm.debugMessages, function (d) {
              return _c("li", { key: d }, [
                _vm._v("\n            " + _vm._s(d) + "\n        "),
              ])
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }