<template>
    <span class="vue-widget-header-button-wrapper">
        <slot name="buttons"> </slot>
    </span>
</template>

<script>
export default {
    name: "ButtonWrapper",
};
</script>

<style lang="scss" scoped>
.vue-widget-header-button-wrapper {
    display: flex;
    padding: 2px 1px 2px 0;
    vertical-align: middle;
    > * {
        padding-left: 4px;
    }
}
</style>
