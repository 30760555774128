export const HAS_MOUNTED = "HAS_MOUNTED";

export const QUEUE_ADD = "QUEUE_ADD";
export const QUEUE_TASK_COMPLETE = "QUEUE_TASK_COMPLETE";

export const ROLE_RIGHT_SET = "ROLE_RIGHT_SET";
export const ROLE_RIGHT_LOADING = "ROLE_RIGHT_LOADING";
export const HELP_OPEN = "HELP_OPEN";
export const POPUP_WIDGET_OPEN = "POPUP_WIDGET_OPEN";

export const SIGN_COLLECTIONS_GET = "SIGN_COLLECTIONS_GET";
export const SIGN_COLLECTIONS_SET_CONTEXT = "SIGN_COLLECTIONS_SET_CONTEXT";
export const SIGN_COLLECTIONS_ERROR = "SIGN_COLLECTIONS_ERROR";

export const OCCUPANCY_SETTINGS_GET = "OCCUPANCY_SETTINGS_GET";
export const OCCUPANCY_SETTINGS_SET_CONTEXT = "OCCUPANCY_SETTINGS_SET_CONTEXT";
export const OCCUPANCY_SETTINGS_SET_DB = "OCCUPANCY_SETTINGS_SET_DB";
export const OCCUPANCY_SETTINGS_ERROR = "OCCUPANCY_SETTINGS_ERROR";

export const LOG = "LOG";
