var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "vue-widget-display-root",
      class: [
        { "vue-widget-maximised": _vm.isMaximised },
        { "vue-widget-error": _vm.inError },
        { "vue-widget-attention": _vm.widgetRequiresAttention },
      ],
      attrs: { id: "widgetDisplayRoot" + _vm.widgetId + _vm.widgetName },
    },
    [
      _vm.isBusy ? _c("AmBusy") : _vm._e(),
      _vm._v(" "),
      _c("WidgetHeader", {
        attrs: {
          id: "widgetHeader" + _vm.widgetId,
          "colour-gradient1": _vm.headerColourGradient1,
          "colour-gradient2": _vm.headerColourGradient2,
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("WidgetHeaderText", {
                  attrs: { "widget-id": _vm.widgetId },
                }),
                _vm._v(" "),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isUpdating,
                      expression: "isUpdating",
                    },
                  ],
                  staticClass: "vue-widget-update-progress",
                  attrs: {
                    src: "/dist/images/General/progress-bar.gif",
                    alt: "update indicator",
                  },
                }),
                _vm._v(" "),
                _c("WidgetHeaderButtons", {
                  attrs: { "widget-id": _vm.widgetId },
                  on: { "save-location-info": _vm.saveLocationInfo },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _vm.showDebug
        ? _c("WidgetDebug", { attrs: { "widget-id": _vm.widgetId } })
        : _vm._e(),
      _vm._v(" "),
      _c("WidgetContent", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isMinimised,
            expression: "!isMinimised",
          },
        ],
        attrs: { "widget-id": _vm.widgetId, "widget-name": _vm.widgetName },
        on: {
          "widget-requires-attention": function ($event) {
            _vm.widgetRequiresAttention = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }