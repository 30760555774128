var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ButtonWrapper", {
    scopedSlots: _vm._u([
      {
        key: "buttons",
        fn: function () {
          return [
            !_vm.inError
              ? _c(
                  "div",
                  _vm._l(_vm.toggles, function (t) {
                    return _c(
                      "a",
                      {
                        key: t.id,
                        attrs: {
                          title: `${
                            typeof t.config.tooltip === "undefined"
                              ? null
                              : t.config.tooltip
                          }`,
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.onToggleClick(t.id)
                          },
                        },
                      },
                      [_c("i", { class: `fa-lg ${t.config.iconClass}` })]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "click-outside",
                    rawName: "v-click-outside",
                    value: _vm.hideMenu,
                    expression: "hideMenu",
                  },
                ],
                staticClass: "vue-widget-menu-wrapper",
                attrs: { id: "widgetMenuWrapper" + _vm.widgetId },
              },
              [
                !_vm.inError
                  ? _c(
                      "a",
                      {
                        attrs: { title: _vm.$t("menu") },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            _vm.showMenu = !_vm.showMenu
                          },
                        },
                      },
                      [_c("i", { staticClass: "fa-lg fa-solid fa-bars" })]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showMenu,
                        expression: "showMenu",
                      },
                    ],
                    staticClass: "vue-widget-menu",
                    attrs: { id: "widgetMenu" + _vm.widgetId },
                  },
                  [
                    _vm.canMinimise
                      ? _c(
                          "button",
                          {
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.onMinimiseClick.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t("minimise")) +
                                "\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.canMaximise
                      ? _c(
                          "button",
                          {
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.onMaximiseClick.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t("maximise")) +
                                "\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.canRestore
                      ? _c(
                          "button",
                          {
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.onRestoreClick.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t("restore")) +
                                "\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.onDeleteClick.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("delete")) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("hr", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.canOpenInNewWindow,
                          expression: "canOpenInNewWindow",
                        },
                      ],
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.canPrint,
                            expression: "canPrint",
                          },
                        ],
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.onPrintClick.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("print")) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.canExport,
                            expression: "canExport",
                          },
                        ],
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.onExportClick.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("export")) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("hr", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.canPauseUpdates ||
                            _vm.canResumeUpdates ||
                            !_vm.isSettingsOpen,
                          expression:
                            "\n                        canPauseUpdates ||\n                        canResumeUpdates ||\n                        !isSettingsOpen\n                    ",
                        },
                      ],
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.canPauseUpdates,
                            expression: "canPauseUpdates",
                          },
                        ],
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.onPauseClick.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("pause")) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.canResumeUpdates,
                            expression: "canResumeUpdates",
                          },
                        ],
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.onResumeClick.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("resume")) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.canOpenSettings
                      ? _c(
                          "button",
                          {
                            staticClass: "vue-icon vue-icon-settings",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.onSettingsOpenClick.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t("settings")) +
                                "\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.widgetHasHelp ? _c("hr") : _vm._e(),
                    _vm._v(" "),
                    _vm.widgetHasHelp
                      ? _c(
                          "button",
                          {
                            staticClass: "vue-icon vue-icon-help",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.onHelpClick.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t("help")) +
                                "\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c("ButtonClose", { on: { clicked: _vm.onDeleteClick } }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }