// JS initializes the Date object using the client system region settings.
// To use the correct Horizon System date, we need both the client and Horizon System offset

// Get a date object from ticks adjusted to the supplied offset
export const getOffsetTime = (offsetInMs, ticks = null) => {
    const date = ticks === null ? new Date() : new Date(ticks);
    const clientTimeZoneOffset = date.getTimezoneOffset() * 60 * 1000;
    const offsetTime = date.getTime() + clientTimeZoneOffset + offsetInMs;
    date.setTime(offsetTime);
    return date;
};

// Translate the supplied date from the browser time offset to the supplied offset and then return the ISO string
export const getOffsetTimeISOString = (offsetInMs, dateToConvert) => {
    const clientTimeZoneOffset = dateToConvert.getTimezoneOffset() * 60 * 1000;
    const offsetTime =
        dateToConvert.getTime() - clientTimeZoneOffset - offsetInMs;
    const date = new Date();
    date.setTime(offsetTime);
    return date.toISOString();
};

// Return a localised timespan string
export const getTimespanString = (locale, dtStart, dtEnd = Date.now()) => {
    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;

    const elapsed = dtEnd - dtStart;

    const options = {
        style: "unit",
        unit: "second",
        unitDisplay: "long",
    };

    if (elapsed < msPerMinute) {
        return Intl.NumberFormat(locale, options).format(
            Math.floor(elapsed / 1000)
        );
    }

    if (elapsed < msPerHour) {
        return Intl.NumberFormat(locale, { ...options, unit: "minute" }).format(
            Math.floor(elapsed / 1000 / 60)
        );
    }

    if (elapsed < msPerDay) {
        return Intl.NumberFormat(locale, { ...options, unit: "hour" }).format(
            Math.floor(elapsed / 1000 / 60 / 60)
        );
    }

    return Intl.NumberFormat(locale, { ...options, unit: "day" }).format(
        Math.floor(elapsed / 1000 / 60 / 60 / 24)
    );
};
