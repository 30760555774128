<template>
    <a :title="$t('close')" @click.prevent="$emit('clicked')">
        <i class="fa-lg fa-regular fa-circle-xmark"></i>
    </a>
</template>

<script>
import * as EnglishStrings from "../../localisation/buttons.en.json";
import * as FrenchStrings from "../../localisation/buttons.fr.json";

export default {
    name: "ButtonClose",
    emits: ["clicked"],
    i18n: {
        messages: {
            en: EnglishStrings,
            fr: FrenchStrings,
        },
    },
};
</script>
