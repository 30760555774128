<template>
    <div id="widgetHeader" class="vue-widget-header">
        <slot name="content" />
    </div>
</template>

<script>
export default {
    name: "WidgetHeader",
    props: {
        colourGradient1: {
            type: String,
            default: "var(--skin-primary-background)",
        },
        colourGradient2: {
            type: String,
            default: "var(--skin-primary-background-gradient)",
        },
    },
};
</script>

<style lang="scss" scoped>
.vue-widget-header {
    background-color: v-bind(colourGradient1);
    background-image: linear-gradient(
        to right,
        v-bind(colourGradient1),
        v-bind(colourGradient2)
    );
    margin-bottom: 0px;
    padding: 4px 2px 3px 2px; // magic numbers to make it like v1 widgets
}
</style>
