export const HAS_CREATED = "HAS_CREATED";
export const HAS_MOUNTED = "HAS_MOUNTED";
export const HAS_HYDRATED = "HAS_HYDRATED";

export const WIDGET_ASCX_LOADED = "WIDGET_ASCX_LOADED";

export const INSTANCE_STATE_LOADED = "INSTANCE_STATE_LOADED";
export const INSTANCE_STATE_UPDATED = "INSTANCE_STATE_UPDATED";

export const HAS_SETTINGS = "HAS_SETTINGS";
export const SETTINGS_OPEN = "SETTINGS_OPEN";
export const SETTINGS_CLOSE = "SETTINGS_CLOSE";
export const SETTINGS_VALIDATION_UPDATE = "SETTINGS_VALIDATION_UPDATE";

export const UPDATE_REQUEST_FROM_HORIZON = "UPDATE_REQUEST_FROM_HORIZON";
export const UPDATE_REQUEST_FROM_HORIZON_FORCED =
    "UPDATE_REQUEST_FROM_HORIZON_FORCED";
export const UPDATE_REQUEST_FROM_WIDGET = "UPDATE_REQUEST_FROM_WIDGET";
export const UPDATE_COMPLETE = "UPDATE_COMPLETE";
export const UPDATE_RESET = "UPDATE_RESET";

export const PAUSE_UPDATES = "PAUSE_UPDATES";
export const RESUME_UPDATES = "RESUME_UPDATES";
export const ERROR = "ERROR";
export const SET_MODAL_RESULT = "SET_MODAL_RESULT";
export const MODAL_RESULT_RETRIEVED = "MODAL_RESULT_RETRIEVED";

export const REFRESH_TRIGGERED = "REFRESH_TRIGGERED";
export const REGISTER_TRANSIENT_DATA_IDS = "REGISTER_TRANSIENT_DATA_IDS";
export const SAVE_DATA = "SAVE_DATA";
export const HAS_RESTORED_DATA = "HAS_RESTORED_DATA";
export const HORIZON_EVENT_RAISED = "HORIZON_EVENT_RAISED";
export const HORIZON_EVENT_HEARD = "HORIZON_EVENT_HEARD";
export const HORIZON_OVERLAY_SHOWN = "HORIZON_OVERLAY_SHOWN";
export const HORIZON_OVERLAY_HIDDEN = "HORIZON_OVERLAY_HIDDEN";

export const RESET_TRIGGERED = "RESET_TRIGGERED";
export const RESET_ACTIONED = "RESET_ACTIONED";

export const FORCE_RELOAD = "FORCE_RELOAD";
export const FORCE_RELOAD_TRIGGERED = "FORCE_RELOAD_TRIGGERED";

export const DESTROY = "DESTROY";
export const DESTROYED = "DESTROYED";

export const CONTAINER_RENAMED = "CONTAINER_RENAMED";
export const HEADER_USER_CONNECTION_UPDATED = "HEADER_USER_CONNECTION_UPDATED";
export const HEADER_INTERCHANGE_NAME_UPDATED =
    "HEADER_INTERCHANGE_NAME_UPDATED";

export const TOGGLE_ADD = "TOGGLE_ADD";
export const TOGGLE_CHANGE_CONFIG = "TOGGLE_CHANGE_CONFIG";
export const TOGGLE = "TOGGLE";

// Namespaced events to be sent to the Display machine
export const DISPLAY_MINIMISING = "DISPLAY_MINIMISING";
export const DISPLAY_MAXIMISING = "DISPLAY_MAXIMISING";
export const DISPLAY_RESTORE = "DISPLAY_RESTORE";
export const DISPLAY_VISUAL_CHANGE_COMPLETE = "DISPLAY_VISUAL_CHANGE_COMPLETE";
export const DISPLAY_RESIZED = "DISPLAY_RESIZED";
export const DISPLAY_SAVE_LOCATION_INFO = "DISPLAY_SAVE_LOCATION_INFO";
export const DISPLAY_ANOTHER_WIDGET_WAS_MAXIMISED =
    "DISPLAY_ANOTHER_WIDGET_WAS_MAXIMISED";

// Namespaced events to be sent to the Settings machine
export const SETTINGS_MACHINE_OPEN = "SETTINGS_MACHINE_OPEN";
export const SETTINGS_MACHINE_OPENING_STATE = "SETTINGS_MACHINE_OPENING_STATE";
export const SETTINGS_MACHINE_CLOSE = "SETTINGS_MACHINE_CLOSE";
export const SETTINGS_MACHINE_SAVE_TRIGGERED =
    "SETTINGS_MACHINE_SAVE_TRIGGERED";
export const SETTINGS_MACHINE_CANCEL_TRIGGERED =
    "SETTINGS_MACHINE_CANCEL_TRIGGERED";
export const SETTINGS_MACHINE_RESET_TRIGGERED =
    "SETTINGS_MACHINE_RESET_TRIGGERED";
export const SETTINGS_MACHINE_ACTIONED = "SETTINGS_MACHINE_ACTIONED";

export const SETTINGS_MACHINE_VALIDATION_RESULT =
    "SETTINGS_MACHINE_VALIDATION_RESULT";
export const SETTINGS_MACHINE_UPDATED = "SETTINGS_MACHINE_UPDATED";
