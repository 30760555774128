import { createMachine, assign, sendParent } from "xstate";
import * as WidgetDisplayStates from "../constants/widgetDisplayStates";
import * as WidgetEvents from "../constants/widgetEvents";

export default {
    widgetDisplayStateMachineConstructor(widgetId, initialState) {
        let initialDisplayState;
        switch (initialState) {
            case 0:
                initialDisplayState = WidgetDisplayStates.MINIMISED;
                break;
            case 2:
                initialDisplayState = WidgetDisplayStates.MAXIMISED;
                break;
            default:
                initialDisplayState = WidgetDisplayStates.NORMAL;
                break;
        }
        return createMachine(
            {
                id: "display",
                context: {
                    widgetId,
                    previousZoneId: null,
                    previousPrecedingSiblingId: null,
                    height: 0,
                    width: 0,
                },
                initial: initialDisplayState,
                on: {
                    DISPLAY_MAXIMISING: WidgetDisplayStates.MAXIMISING,
                    DISPLAY_MINIMISING: WidgetDisplayStates.MINIMISING,
                    DISPLAY_SAVE_LOCATION_INFO: {
                        actions: assign({
                            previousZoneId: (context, event) => event.zoneId,
                            previousPrecedingSiblingId: (context, event) =>
                                event.precedingSiblingId,
                        }),
                    },
                    DISPLAY_RESIZED: [
                        {
                            cond: "hasHeightChanged",
                            actions: [
                                assign({
                                    height: (context, event) => event.height,
                                    width: (context, event) => event.width,
                                }),
                                "horizonSetWidgetHeight",
                            ],
                        },
                        {
                            actions: assign({
                                height: (context, event) => event.height,
                                width: (context, event) => event.width,
                            }),
                        },
                    ],
                },
                states: {
                    NORMAL: {},
                    MINIMISING: {
                        always: {
                            target: WidgetDisplayStates.MINIMISED,
                        },
                        exit: [
                            "horizonSetWidgetMinimised",
                            sendParent(WidgetEvents.PAUSE_UPDATES),
                        ], // these are on exit conditions as the main states will be entered on widget startup/refresh
                    },
                    MINIMISED: {
                        on: {
                            DESTROY: WidgetDisplayStates.NORMAL,
                            DISPLAY_RESTORE: WidgetDisplayStates.NORMAL,
                        },
                        exit: [
                            sendParent(WidgetEvents.RESUME_UPDATES),
                            "horizonSetWidgetRestoredFromMin",
                        ],
                    },
                    MAXIMISING: {
                        entry: "horizonResetLastMaxWidget",
                        on: {
                            DISPLAY_VISUAL_CHANGE_COMPLETE:
                                WidgetDisplayStates.MAXIMISED,
                        },
                        exit: "horizonSetWidgetMaximised",
                    },
                    REVERTING_FROM_MAX_FROM_WIDGET_TRIGGER: {
                        entry: "horizonResetLastMaxWidget",
                        on: {
                            DISPLAY_VISUAL_CHANGE_COMPLETE:
                                WidgetDisplayStates.NORMAL,
                        },
                    },
                    REVERTING_FROM_MAX_FROM_HORIZON_TRIGGER: {
                        on: {
                            DISPLAY_VISUAL_CHANGE_COMPLETE:
                                WidgetDisplayStates.NORMAL,
                        },
                    },
                    MAXIMISED: {
                        entry: "horizonUpdateLastMaxWidget",
                        on: {
                            DESTROY:
                                WidgetDisplayStates.REVERTING_FROM_MAX_FROM_WIDGET_TRIGGER,
                            DISPLAY_ANOTHER_WIDGET_WAS_MAXIMISED:
                                WidgetDisplayStates.REVERTING_FROM_MAX_FROM_HORIZON_TRIGGER,
                            DISPLAY_RESTORE:
                                WidgetDisplayStates.REVERTING_FROM_MAX_FROM_WIDGET_TRIGGER,
                        },
                        exit: "horizonSetWidgetRestoredFromMax",
                    },
                },
            },
            {
                guards: {
                    hasHeightChanged: (context, event) => {
                        return context.height !== event.height;
                    },
                },
                actions: {
                    horizonSetWidgetMaximised: (context) => {
                        AcisHorizon.Web.Framework.WidgetService.MaximizeWidgetInstance(
                            context.widgetId
                        );
                    },
                    horizonSetWidgetMinimised: (context) => {
                        AcisHorizon.Web.Framework.WidgetService.CollapseWidgetInstance(
                            context.widgetId
                        );
                    },
                    horizonSetWidgetRestoredFromMax: (context) => {
                        AcisHorizon.Web.Framework.WidgetService.RestoreWidgetInstance(
                            context.widgetId
                        );
                    },
                    horizonSetWidgetRestoredFromMin: (context) => {
                        AcisHorizon.Web.Framework.WidgetService.ExpandWidgetInstance(
                            context.widgetId
                        );
                    },
                    horizonSetWidgetHeight: (context, event) => {
                        AcisHorizon.Web.Framework.WidgetService.ResizeWidgetInstance(
                            context.widgetId,
                            0,
                            event.height
                        );
                    },
                    horizonResetLastMaxWidget: () => {
                        PlatformUI.resetLastMaximisedWidget();
                    },
                    horizonUpdateLastMaxWidget: (context) => {
                        PlatformUI._LastMaximizedWidget =
                            new WidgetMaximizeBehavior(context.widgetId, true);
                    },
                },
            }
        );
    },
};
