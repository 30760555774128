<template>
    <ButtonWrapper>
        <template #buttons>
            <div v-if="!inError">
                <a
                    v-for="t in toggles"
                    :key="t.id"
                    :title="`${
                        typeof t.config.tooltip === 'undefined'
                            ? null
                            : t.config.tooltip
                    }`"
                    @click.prevent="onToggleClick(t.id)"
                >
                    <i :class="`fa-lg ${t.config.iconClass}`"></i>
                </a>
            </div>
            <div
                :id="'widgetMenuWrapper' + widgetId"
                v-click-outside="hideMenu"
                class="vue-widget-menu-wrapper"
            >
                <a
                    v-if="!inError"
                    :title="$t('menu')"
                    @click.prevent="showMenu = !showMenu"
                >
                    <i class="fa-lg fa-solid fa-bars"></i>
                </a>
                <div
                    v-show="showMenu"
                    :id="'widgetMenu' + widgetId"
                    class="vue-widget-menu"
                >
                    <button v-if="canMinimise" @click.prevent="onMinimiseClick">
                        {{ $t("minimise") }}
                    </button>
                    <button v-if="canMaximise" @click.prevent="onMaximiseClick">
                        {{ $t("maximise") }}
                    </button>
                    <button v-if="canRestore" @click.prevent="onRestoreClick">
                        {{ $t("restore") }}
                    </button>
                    <button @click.prevent="onDeleteClick">
                        {{ $t("delete") }}
                    </button>
                    <hr v-show="canOpenInNewWindow" />
                    <button v-show="canPrint" @click.prevent="onPrintClick">
                        {{ $t("print") }}
                    </button>
                    <button v-show="canExport" @click.prevent="onExportClick">
                        {{ $t("export") }}
                    </button>
                    <hr
                        v-show="
                            canPauseUpdates ||
                            canResumeUpdates ||
                            !isSettingsOpen
                        "
                    />
                    <button
                        v-show="canPauseUpdates"
                        @click.prevent="onPauseClick"
                    >
                        {{ $t("pause") }}
                    </button>
                    <button
                        v-show="canResumeUpdates"
                        @click.prevent="onResumeClick"
                    >
                        {{ $t("resume") }}
                    </button>
                    <button
                        v-if="canOpenSettings"
                        class="vue-icon vue-icon-settings"
                        @click.prevent="onSettingsOpenClick"
                    >
                        {{ $t("settings") }}
                    </button>
                    <hr v-if="widgetHasHelp" />
                    <button
                        v-if="widgetHasHelp"
                        class="vue-icon vue-icon-help"
                        @click.prevent="onHelpClick"
                    >
                        {{ $t("help") }}
                    </button>
                </div>
            </div>
            <ButtonClose @clicked="onDeleteClick" />
        </template>
    </ButtonWrapper>
</template>

<script>
import vClickOutside from "v-click-outside";
import ButtonClose from "../buttons/ButtonClose.vue";
import ButtonWrapper from "../buttons/ButtonWrapper.vue";
import * as HorizonEvents from "../../constants/horizonEvents";
import * as HorizonTasks from "../../constants/horizonTasks";
import * as WidgetStates from "../../constants/widgetStates";
import * as ModalTypes from "../../constants/modalTypes";
import * as WidgetDisplayStates from "../../constants/widgetDisplayStates";
import * as WidgetEvents from "../../constants/widgetEvents";
import * as HorizonRoleRights from "../../constants/horizonRoleRights";
import * as PageTypes from "../../constants/pageTypes";
import * as EnglishStrings from "../../localisation/components/widgets/widgetHeaderButtons.en.json";
import * as FrenchStrings from "../../localisation/components/widgets/widgetHeaderButtons.fr.json";

export default {
    name: "WidgetHeaderButtons",
    components: {
        ButtonClose,
        ButtonWrapper,
    },
    directives: {
        clickOutside: vClickOutside.directive,
    },
    props: {
        widgetId: {
            type: Number,
            required: true,
        },
    },
    emits: ["save-location-info"],
    data() {
        return {
            showMenu: false,
        };
    },
    computed: {
        currentState() {
            return this.$store.state[`widget${this.widgetId}`].currentState;
        },
        isPaused() {
            return this.currentState === WidgetStates.PAUSED;
        },
        inError() {
            return this.currentState === WidgetStates.ERRORED;
        },
        isSettingsOpen() {
            return this.currentState === WidgetStates.SETTINGS_OPEN;
        },
        widgetHasSettings() {
            return this.$store.getters[`widget${this.widgetId}/hasSettings`];
        },
        displayState() {
            return this.$store.state[`widget${this.widgetId}`].context
                .displayMachine.state.value;
        },
        isDisplayNormal() {
            return this.displayState === WidgetDisplayStates.NORMAL;
        },
        isMinimised() {
            return this.displayState === WidgetDisplayStates.MINIMISED;
        },
        isMaximised() {
            return this.displayState === WidgetDisplayStates.MAXIMISED;
        },
        userCanPrint() {
            const hasRoleRight = this.$store.getters.getRoleRight(
                HorizonRoleRights.WIDGET_PRINT
            );
            if (hasRoleRight === null) {
                this.$store.dispatch(
                    "loadRoleRight",
                    HorizonRoleRights.WIDGET_PRINT
                );
            }
            return hasRoleRight;
        },
        userCanExport() {
            const hasRoleRight = this.$store.getters.getRoleRight(
                HorizonRoleRights.WIDGET_EXPORT
            );
            if (hasRoleRight === null) {
                this.$store.dispatch(
                    "loadRoleRight",
                    HorizonRoleRights.WIDGET_EXPORT
                );
            }
            return hasRoleRight;
        },
        userCanPause() {
            const hasRoleRight = this.$store.getters.getRoleRight(
                HorizonRoleRights.WIDGET_FREEZE
            );
            if (hasRoleRight === null) {
                this.$store.dispatch(
                    "loadRoleRight",
                    HorizonRoleRights.WIDGET_FREEZE
                );
            }
            return hasRoleRight;
        },
        widgetCanPrint() {
            return this.$store.getters[`widget${this.widgetId}/canPrint`];
        },
        widgetCanExport() {
            return this.$store.getters[`widget${this.widgetId}/canExport`];
        },
        widgetCanPause() {
            return this.$store.getters[`widget${this.widgetId}/canPause`];
        },
        canMinimise() {
            return !this.isSettingsOpen && this.isDisplayNormal;
        },
        canMaximise() {
            return (
                !this.isSettingsOpen &&
                this.isDisplayNormal &&
                this.$store.state[`widget${this.widgetId}`].context
                    .containerData.size.canMax
            );
        },
        canRestore() {
            return (
                !this.isSettingsOpen && (this.isMinimised || this.isMaximised)
            );
        },
        canPrint() {
            return (
                this.isWidgetContentVisible &&
                this.userCanPrint &&
                this.widgetCanPrint
            );
        },
        canExport() {
            return (
                this.isWidgetContentVisible &&
                this.userCanExport &&
                this.widgetCanExport
            );
        },
        canOpenInNewWindow() {
            return this.canExport || this.canPrint;
        },
        canPauseUpdates() {
            return (
                this.isWidgetContentVisible &&
                this.userCanPause &&
                this.widgetCanPause &&
                !this.isPaused
            );
        },
        canOpenSettings() {
            return this.widgetHasSettings && this.isWidgetContentVisible;
        },
        isWidgetContentVisible() {
            return !this.isSettingsOpen && !this.isMinimised;
        },
        canResumeUpdates() {
            return !this.isSettingsOpen && !this.isMinimised && this.isPaused;
        },
        widgetHasHelp() {
            return this.helpUrl !== null && this.helpUrl !== "";
        },
        helpUrl() {
            return this.$store.getters[`widget${this.widgetId}/helpUrl`];
        },
        toggles() {
            return this.$store.getters[`widget${this.widgetId}/toggles`];
        },
        pageType() {
            return this.$store.getters[`widget${this.widgetId}/pageType`];
        },
    },
    methods: {
        hideMenu() {
            this.showMenu = false;
        },
        onSettingsOpenClick() {
            this.hideMenu();
            this.$store.commit(
                `widget${this.widgetId}/sendEvent`,
                WidgetEvents.SETTINGS_OPEN
            );
        },
        onMaximiseClick() {
            this.hideMenu();
            this.$emit("save-location-info");
            this.$store.commit(
                `widget${this.widgetId}/sendEvent`,
                WidgetEvents.DISPLAY_MAXIMISING
            );
        },
        onMinimiseClick() {
            this.hideMenu();
            this.$store.commit(
                `widget${this.widgetId}/sendEvent`,
                WidgetEvents.DISPLAY_MINIMISING
            );
        },
        onRestoreClick() {
            this.hideMenu();
            this.$store.commit(
                `widget${this.widgetId}/sendEvent`,
                WidgetEvents.DISPLAY_RESTORE
            );
        },
        onDeleteClick() {
            this.hideMenu();
            if (this.pageType === PageTypes.TRANSIENT) {
                window.close();
                return;
            }
            this.$store.commit("sendEvent", {
                type: HorizonEvents.QUEUE_ADD,
                task: {
                    type: HorizonTasks.SHOW_MODAL,
                    data: {
                        widgetId: this.widgetId,
                        modalType: ModalTypes.DELETE_WIDGET,
                    },
                },
            });
        },
        onPrintClick() {
            this.hideMenu();
            window.open(`/Display?q=${btoa(`id=${this.widgetId}&p=1`)}`);
        },
        onExportClick() {
            this.hideMenu();
            window.open(`/Display?q=${btoa(`id=${this.widgetId}&e=1`)}`);
        },
        onPauseClick() {
            this.hideMenu();
            this.$store.commit(
                `widget${this.widgetId}/sendEvent`,
                WidgetEvents.PAUSE_UPDATES
            );
        },
        onResumeClick() {
            this.hideMenu();
            this.$store.commit(
                `widget${this.widgetId}/sendEvent`,
                WidgetEvents.RESUME_UPDATES
            );
        },
        onHelpClick() {
            this.hideMenu();
            this.$store.commit("sendEvent", {
                type: HorizonEvents.QUEUE_ADD,
                task: {
                    type: HorizonTasks.SHOW_HELP,
                    helpUrl: this.helpUrl,
                },
            });
        },
        onToggleClick(toggleId) {
            this.$store.commit(`widget${this.widgetId}/sendEvent`, {
                type: WidgetEvents.TOGGLE,
                id: toggleId,
            });
        },
    },
    i18n: {
        messages: {
            en: EnglishStrings,
            fr: FrenchStrings,
        },
    },
};
</script>

<style lang="scss" scoped>
.vue-widget-menu-wrapper {
    display: inline-block;
}
.vue-icon {
    background-position: 6px center;
    background-repeat: no-repeat;
    line-height: 20px;
}

.vue-icon-settings {
    /* webpackIgnore: true */
    background-image: url(/dist/images/WidgetMenu/page_white_edit.png);
}

.vue-icon-help {
    /* webpackIgnore: true */
    background-image: url(/dist/images/WidgetMenu/information.png);
}

.vue-widget-menu {
    background-color: var(--colour-context-menu-background);
    border: 1px solid var(--colour-context-menu-border);
    cursor: pointer;
    position: absolute;
    width: 150px;
    right: 0px;
    z-index: 99999;

    hr {
        border-top: 1px solid var(--colour-context-menu-border);
        margin: 0px;
    }

    button {
        box-shadow: none;
        border: none;
        display: block;
        line-height: 20px;
        padding: 1px 5px 1px 28px;
        text-align: left;
        width: 100%;

        &:hover {
            background-color: var(--colour-context-menu-hover);
            color: var(--colour-site-background-one);
        }

        & > * {
            display: inline-block;
        }
    }
}
</style>
