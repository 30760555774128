<template>
    <div
        :id="'widgetDebug' + widgetId"
        class="vue-widget-debug"
        :class="{ 'vue-widget-debug-error': inError }"
    >
        <span
            >{{ widgetId }}: {{ platformState }} | {{ currentState }} |
            {{ displayCurrentState }} | {{ settingsCurrentState }}</span
        >
        <ul v-if="hasDebugMessages">
            <li v-for="d in debugMessages" :key="d">
                {{ d }}
            </li>
        </ul>
    </div>
</template>

<script>
import * as WidgetStates from "../../constants/widgetStates";

export default {
    name: "WidgetDebug",
    props: {
        widgetId: {
            type: Number,
            required: true,
        },
    },
    computed: {
        platformState() {
            return this.$store.state.currentState;
        },
        currentState() {
            return this.$store.state[`widget${this.widgetId}`].currentState;
        },
        displayCurrentState() {
            return this.$store.state[`widget${this.widgetId}`].context
                .displayMachine.state.value;
        },
        settingsCurrentState() {
            return this.$store.state[`widget${this.widgetId}`].context
                .settingsMachine.state.value;
        },
        inError() {
            return this.currentState === WidgetStates.ERRORED;
        },
        debugMessages() {
            return this.$store.state[`widget${this.widgetId}`].context
                .debugMessages;
        },
        hasDebugMessages() {
            return this.debugMessages.length > 0;
        },
    },
};
</script>

<style lang="scss" scoped>
.vue-widget-debug {
    background-color: var(--v2-debug-background);
    color: var(--v2-debug-text);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 0px;
    padding: 2px;
    text-align: left;
    span {
        font-size: 8pt;
        font-weight: bold;
        padding-left: 2px;
        padding-right: 0px;
    }
}

.vue-widget-debug-error {
    background-color: var(--vue-widget-error-lowlight);
}
</style>
