var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      attrs: { title: _vm.$t("close") },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.$emit("clicked")
        },
      },
    },
    [_c("i", { staticClass: "fa-lg fa-regular fa-circle-xmark" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }