export const INITIALISE_STATE_MACHINE = "INITIALISE_STATE_MACHINE";
export const INITIALISING_WIDGET = "INITIALISING_WIDGET";

export const HYDRATING = "HYDRATING";

export const SETTINGS_OPEN = "SETTINGS_OPEN";

export const UPDATING_DATA = "UPDATING_DATA";
export const UPDATE_COMPLETED = "UPDATE_COMPLETED";

export const IDLE = "IDLE";
export const PAUSED = "PAUSED";
export const ERRORED = "ERRORED";

export const REFRESH = "REFRESH";
export const REFRESHING = "REFRESHING";
export const RESTORE_DATA = "RESTORE_DATA";

export const RESET = "RESET";

export const DESTROYING = "DESTROYING";
export const DESTROYED = "DESTROYED";
