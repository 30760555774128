<template>
    <div :id="'widgetInstance' + widgetId + widgetName"></div>
</template>

<script>
import Vue from "vue";
import WidgetDisplayRoot from "./WidgetDisplayRoot.vue";
import * as WidgetStates from "../../constants/widgetStates";
import * as HorizonEvents from "../../constants/horizonEvents";
import * as HorizonTasks from "../../constants/horizonTasks";

export default {
    name: "WidgetInstance",
    props: {
        widgetId: {
            type: Number,
            required: true,
        },
        widgetName: {
            type: String,
            required: true,
        },
    },
    computed: {
        isDestroying() {
            return (
                this.$store.state[`widget${this.widgetId}`].currentState ===
                WidgetStates.DESTROYING
            );
        },
    },
    watch: {
        isDestroying(isDestroying) {
            if (!isDestroying) {
                return;
            }
            this.$store.commit("sendEvent", {
                type: HorizonEvents.QUEUE_ADD,
                task: {
                    type: HorizonTasks.WIDGET_REMOVE,
                    widgetId: this.widgetId,
                },
            });
        },
    },
    created() {
        // Need to construct the widget like this to mount it in the appropriate place in the DOM
        const ContainerConstructor = Vue.extend(WidgetDisplayRoot);
        new ContainerConstructor({
            parent: this,
            propsData: {
                widgetId: this.widgetId,
                widgetName: this.widgetName,
            },
        }).$mount(`#WidgetDisplayRoot${this.widgetId}`);
    },
    beforeDestroy() {
        // Remove the container we created to avoid remnants - this has to be done manually as we created it manually
        this.$children[0].destroyContainer();
    },
};
</script>
