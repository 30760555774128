import { createMachine, assign, spawn, raise, sendTo } from "xstate";
import * as X2j from "xml-js";
import * as WidgetStates from "../constants/widgetStates";
import * as WidgetEvents from "../constants/widgetEvents";
import * as PageTypes from "../constants/pageTypes";
import widgetDisplayStateMachine from "./widgetDisplayStateMachine";
import widgetSettingsStateMachine from "./widgetSettingsStateMachine";
import * as LogLevels from "../constants/logLevels";
import * as ObjectUtils from "../../scripts/utils/objectUtils";
import * as HorizonErrors from "../constants/horizonErrors";
import horizonStore from "../store";

const debug = process.env.NODE_ENV !== "production";
const instanceStateArrayMergeStrategy = ObjectUtils.ARRAY_HANDLING_REPLACE;

// only 1 widget currently converted to the new state loading style
const isWidgetsUsingSettingsMachine = (x) =>
    ["WidgetJourneyCancellations"].findIndex((y) => y === x) !== -1;

// containerData is setup in WidgetContainerVue.ascx.cs
export default {
    widgetStateMachineConstructor(widgetId, widgetName, containerData) {
        return createMachine(
            {
                id: `widget${widgetId}`,
                context: {
                    displayMachine: null,
                    settingsMachine: null,

                    // read only widget data supplied during setup
                    widgetId,
                    widgetName,
                    containerData: JSON.parse(containerData),
                    widgetData: null,

                    // widgetInstanceState for the database
                    instanceState: null,

                    // life-cycle flags
                    isAscxLoaded: false,
                    isMounted: false,
                    isWidgetCreated: false,
                    hasSettings: false,
                    isHydrated: false,
                    isUpdatePending: false,
                    forceReload: false,
                    isBehindObjects: 0, // how many objects in Horizon have decided they want to be on top (e.g. modals, help)

                    // life-cycle data
                    errorType: null,
                    debugMessages: new Set(),
                    modalResults: [],
                    toggles: [],
                    transientData: {},
                    transientDataIds: {},
                    header: {
                        userConnectionTitleText: null,
                        interchangeName: null,
                    },

                    // HACK - Revisit this! (JL 2021-07-29)
                    // stateWhenRefreshed is pretty ugly but can't find a better way to keep Widget in certain states (e.g. settings & paused) through a refresh
                    // It complicates the machine and particularly the guards
                    stateWhenRefreshed: null,
                },
                initial: WidgetStates.INITIALISE_STATE_MACHINE,
                on: {
                    "*": [
                        {
                            cond: (context, event) =>
                                event.type.startsWith("DISPLAY_"),
                            actions: [
                                (context, event) => {
                                    if (debug) {
                                        const eventName =
                                            typeof event === "object" &&
                                            event !== null
                                                ? event.type
                                                : event;
                                        console.log(
                                            `%c${context.widgetId} -> widgetStateMachine.js ${context.displayMachine.state.value} -> event (${eventName})`,
                                            LogLevels.LogLevelColours[
                                                LogLevels.INFO
                                            ]
                                        );
                                    }
                                },
                                sendTo(
                                    (context) => context.displayMachine,
                                    (context, event) => event
                                ),
                            ],
                        },
                        {
                            cond: (context, event) =>
                                event.type.startsWith("SETTINGS_MACHINE_"),
                            actions: [
                                (context, event) => {
                                    if (debug) {
                                        const eventName =
                                            typeof event === "object" &&
                                            event !== null
                                                ? event.type
                                                : event;
                                        console.log(
                                            `%c${context.widgetId} -> widgetStateMachine.js ${context.settingsMachine.state.value} -> event (${eventName})`,
                                            LogLevels.LogLevelColours[
                                                LogLevels.INFO
                                            ]
                                        );
                                    }
                                },
                                sendTo(
                                    (context) => context.settingsMachine,
                                    (context, event) => event
                                ),
                            ],
                        },
                    ],
                    WIDGET_ASCX_LOADED: {
                        actions: assign({
                            widgetData: (context, event) =>
                                JSON.parse(event.data),
                            isAscxLoaded: true,
                        }),
                    },
                    INSTANCE_STATE_LOADED: [
                        {
                            actions: [
                                assign({
                                    instanceState: (context, event) =>
                                        event.instanceState,
                                }),
                            ],
                        },
                    ],
                    INSTANCE_STATE_UPDATED: [
                        {
                            cond: "canUpdateInstanceState",
                            actions: [
                                assign({
                                    instanceState: (context, event) =>
                                        ObjectUtils.mergeDeep(
                                            context.instanceState,
                                            event.instanceState,
                                            instanceStateArrayMergeStrategy
                                        ),
                                }),
                                "instanceStateSave",
                            ],
                        },
                    ],
                    HAS_CREATED: {
                        actions: [
                            assign({ isWidgetCreated: true }),
                            sendTo(
                                (context) => context.settingsMachine,
                                (context, event) => event
                            ),
                        ],
                    },
                    HAS_HYDRATED: [
                        {
                            cond: "canHorizonTriggerUpdate",
                            actions: [
                                assign({ isHydrated: true }),
                                "horizonRegisterUpdateFunction",
                            ],
                        },
                        {
                            actions: assign({ isHydrated: true }),
                        },
                    ],
                    HAS_MOUNTED: {
                        actions: assign({ isMounted: true }),
                    },
                    HAS_SETTINGS: {
                        actions: assign({ hasSettings: true }),
                    },
                    UPDATE_REQUEST_FROM_HORIZON: {
                        cond: "canHorizonTriggerNewUpdate",
                        actions: assign({
                            isUpdatePending: true,
                        }),
                    },
                    UPDATE_REQUEST_FROM_HORIZON_FORCED: {
                        actions: assign({
                            isUpdatePending: true,
                        }),
                    },
                    UPDATE_REQUEST_FROM_WIDGET: {
                        cond: "canTriggerNewUpdate",
                        actions: assign({
                            isUpdatePending: true,
                        }),
                    },
                    PAUSE_UPDATES: {
                        cond: "canPauseUpdates",
                        target: WidgetStates.PAUSED,
                    },
                    HORIZON_EVENT_RAISED: {
                        cond: "isListeningForEventFromThisSource",
                        actions: raise((context, event) => ({
                            type: WidgetEvents.HORIZON_EVENT_HEARD,
                            data: event.data,
                        })),
                    },
                    HORIZON_EVENT_HEARD: {
                        cond: "isEventMessageTypeRefreshData",
                        actions: raise({
                            type: WidgetEvents.UPDATE_REQUEST_FROM_HORIZON_FORCED,
                        }),
                    },
                    HORIZON_OVERLAY_SHOWN: {
                        actions: assign({
                            isBehindObjects: (context) =>
                                context.isBehindObjects + 1,
                        }),
                    },
                    HORIZON_OVERLAY_HIDDEN: {
                        actions: assign({
                            isBehindObjects: (context) =>
                                context.isBehindObjects - 1,
                        }),
                    },
                    ERROR: {
                        actions: assign({
                            errorType: (context, event) => event.errorType,
                            debugMessages: (context, event) => {
                                const updatedDebugMessages =
                                    context.debugMessages;
                                if (event.dotNetError !== undefined) {
                                    try {
                                        updatedDebugMessages.add(
                                            `${event.dotNetError._statusCode}: ${event.dotNetError._message} (${event.dotNetError._exceptionType}) | ${event.dotNetError._stackTrace}`
                                        );
                                    } catch (e) {
                                        updatedDebugMessages.add(
                                            `Failed getting dotNetError: ${e._message}`
                                        );
                                    }
                                }
                                if (event.errorMessage !== undefined) {
                                    updatedDebugMessages.add(
                                        event.errorMessage
                                    );
                                }
                                return updatedDebugMessages;
                            },
                        }),
                        target: WidgetStates.ERRORED,
                    },
                    SETTINGS_OPEN: WidgetStates.SETTINGS_OPEN,
                    RESET_TRIGGERED: WidgetStates.RESET,
                    REFRESH_TRIGGERED: {
                        actions: assign({
                            containerData: (context, event) =>
                                JSON.parse(event.containerData),
                            isAscxLoaded: false,
                            isMounted: (context, event) => {
                                return event.alreadyMounted;
                            },
                            stateWhenRefreshed: (
                                context,
                                event,
                                actionMeta
                            ) => {
                                return actionMeta.state.value;
                            },
                        }),
                        target: WidgetStates.REFRESH,
                    },
                    REGISTER_TRANSIENT_DATA_IDS: {
                        actions: assign({
                            transientDataIds: (context, event) => {
                                if (
                                    typeof event.transientDataIds !==
                                    "undefined"
                                ) {
                                    return {
                                        ...context.transientDataIds,
                                        ...event.transientDataIds,
                                    };
                                }
                                return context.transientDataIds;
                            },
                        }),
                    },
                    SAVE_DATA: {
                        actions: assign({
                            transientData: (context, event) => ({
                                ...event.transientData,
                                ...context.transientData,
                            }),
                        }),
                    },
                    HAS_RESTORED_DATA: {
                        actions: assign({
                            transientData: (context, event) => {
                                const transientData = context.transientData;
                                delete transientData[event.transientDataId];
                                return transientData;
                            },
                        }),
                    },
                    FORCE_RELOAD: {
                        actions: assign({
                            forceReload: true,
                            instanceState: null,
                            isHydrated: false,
                            settingsMachine: (context) =>
                                spawn(
                                    widgetSettingsStateMachine.widgetSettingsStateMachineConstructor(
                                        context.widgetId
                                    )
                                ),
                        }),
                    },
                    FORCE_RELOAD_TRIGGERED: {
                        actions: assign({
                            forceReload: false,
                        }),
                    },
                    DESTROY: {
                        actions: sendTo(
                            (context) => context.displayMachine,
                            (context, event) => event
                        ),
                        target: WidgetStates.DESTROYING,
                    },
                    SET_MODAL_RESULT: {
                        actions: assign({
                            modalResults: (context, event) => {
                                const updatedModalList = context.modalResults;
                                updatedModalList.push(event.modalResult);
                                return updatedModalList;
                            },
                        }),
                    },
                    MODAL_RESULT_RETRIEVED: {
                        actions: assign({
                            modalResults: (context) => {
                                const updatedModalList = context.modalResults;
                                updatedModalList.shift();
                                return updatedModalList;
                            },
                        }),
                    },
                    CONTAINER_RENAMED: {
                        actions: "widgetUpdateContainerName",
                    },
                    TOGGLE_ADD: {
                        actions: assign({
                            toggles: (context, event) => {
                                const toggles = context.toggles;
                                const tIndex = toggles.findIndex(
                                    (x) => x.id === event.id
                                );
                                const toggleData = {
                                    id: event.id,
                                    state: event.state,
                                    config: event.config,
                                };
                                if (tIndex === -1) {
                                    toggles.push(toggleData);
                                } else {
                                    toggles[tIndex] = toggleData;
                                }
                                return toggles;
                            },
                        }),
                    },
                    TOGGLE: {
                        actions: assign({
                            toggles: (context, event) => {
                                const toggles = context.toggles;
                                const tIndex = toggles.findIndex(
                                    (x) => x.id === event.id
                                );
                                if (tIndex > -1) {
                                    toggles[tIndex].state =
                                        !toggles[tIndex].state;
                                }
                                return toggles;
                            },
                        }),
                    },
                    TOGGLE_CHANGE_CONFIG: {
                        actions: assign({
                            toggles: (context, event) => {
                                const toggles = context.toggles;
                                const tIndex = toggles.findIndex(
                                    (x) => x.id === event.id
                                );
                                if (tIndex > -1) {
                                    toggles[tIndex].config = {
                                        ...toggles[tIndex].config,
                                        ...event.config,
                                    };
                                }
                                return toggles;
                            },
                        }),
                    },
                    HEADER_USER_CONNECTION_UPDATED: {
                        actions: assign({
                            header: (context, event) => {
                                const header = context.header;
                                header.userConnectionTitleText = event.text;
                                return header;
                            },
                        }),
                    },
                    HEADER_INTERCHANGE_NAME_UPDATED: {
                        actions: assign({
                            header: (context, event) => {
                                const header = context.header;
                                header.interchangeName = event.text;
                                return header;
                            },
                        }),
                    },
                },
                states: {
                    INITIALISE_STATE_MACHINE: {
                        entry: assign({
                            displayMachine: (context) =>
                                spawn(
                                    widgetDisplayStateMachine.widgetDisplayStateMachineConstructor(
                                        context.widgetId,
                                        context.containerData.size
                                            .initDisplayState
                                    ),
                                    { sync: true }
                                ),
                            settingsMachine: (context) =>
                                spawn(
                                    widgetSettingsStateMachine.widgetSettingsStateMachineConstructor(
                                        context.widgetId
                                    )
                                ),
                        }),
                        always: [
                            {
                                target: WidgetStates.PAUSED,
                                cond: "isPausedOnInit",
                            },
                            {
                                target: WidgetStates.INITIALISING_WIDGET,
                            },
                        ],
                    },
                    INITIALISING_WIDGET: {
                        always: [
                            {
                                target: WidgetStates.SETTINGS_OPEN,
                                cond: "settingsLoadedAndAnyInvalid",
                            },
                            {
                                target: WidgetStates.HYDRATING,
                                cond: "initialisationComplete",
                            },
                        ],
                    },
                    HYDRATING: {
                        always: [
                            {
                                target: WidgetStates.SETTINGS_OPEN,
                                cond: "didSetupCompleteAndStateBeforeRefreshSettingsOpen",
                            },
                            {
                                target: WidgetStates.PAUSED,
                                cond: "didSetupCompleteAndStateBeforeRefreshPaused",
                            },
                            {
                                target: WidgetStates.IDLE,
                                cond: "didSetupComplete",
                            },
                        ],
                        exit: assign({
                            stateWhenRefreshed: null,
                        }),
                    },
                    IDLE: {
                        always: [
                            {
                                target: WidgetStates.RESTORE_DATA,
                                cond: "dataSavedFromComponents",
                            },
                            {
                                target: WidgetStates.UPDATING_DATA,
                                cond: "canUpdate",
                            },
                        ],
                    },
                    UPDATING_DATA: {
                        on: {
                            UPDATE_COMPLETE: [
                                {
                                    target: WidgetStates.UPDATE_COMPLETED,
                                    cond: "canHorizonTriggerUpdate",
                                    actions: "horizonNotifyUpdateComplete",
                                },
                                {
                                    target: WidgetStates.UPDATE_COMPLETED,
                                },
                            ],
                        },
                    },
                    UPDATE_COMPLETED: {
                        on: {
                            UPDATE_RESET: {
                                target: WidgetStates.IDLE,
                                actions: assign({
                                    isUpdatePending: false,
                                }),
                            },
                        },
                    },
                    REFRESH: {
                        always: [
                            {
                                target: WidgetStates.REFRESHING,
                                cond: "didSetupComplete",
                            },
                            {
                                target: WidgetStates.INITIALISING_WIDGET,
                                actions: assign({
                                    stateWhenRefreshed: null,
                                    forceReload: true,
                                }),
                            },
                        ],
                    },
                    REFRESHING: {
                        always: {
                            target: WidgetStates.RESTORE_DATA,
                            cond: "initialisationComplete",
                        },
                    },
                    RESTORE_DATA: {
                        always: [
                            {
                                target: WidgetStates.SETTINGS_OPEN,
                                cond: "noDataSavedFromComponentsAndStateBeforeRefreshSettingsOpen",
                            },
                            {
                                target: WidgetStates.PAUSED,
                                cond: "noDataSavedFromComponentsAndStateBeforeRefreshPaused",
                            },
                            {
                                target: WidgetStates.IDLE,
                                cond: "noDataSavedFromComponents",
                            },
                        ],
                        exit: [
                            assign({
                                stateWhenRefreshed: null,
                            }),
                        ],
                    },
                    PAUSED: {
                        entry: "horizonPauseWidgetUpdates",
                        on: {
                            RESUME_UPDATES: [
                                {
                                    target: WidgetStates.RESTORE_DATA,
                                    cond: "dataSavedFromComponents",
                                },
                                {
                                    target: WidgetStates.IDLE,
                                    cond: "didSetupComplete",
                                },
                                {
                                    target: WidgetStates.INITIALISING_WIDGET,
                                },
                            ],
                            REFRESH_TRIGGERED: undefined,
                        },
                        exit: "horizonResumeWidgetUpdates",
                    },
                    SETTINGS_OPEN: {
                        entry: [
                            sendTo(
                                (context) => context.settingsMachine,
                                WidgetEvents.SETTINGS_MACHINE_OPEN
                            ),
                        ],

                        on: {
                            SETTINGS_CLOSE: [
                                {
                                    target: WidgetStates.IDLE,
                                    cond: "didSetupComplete",
                                },
                                {
                                    target: WidgetStates.INITIALISING_WIDGET,
                                },
                            ],
                        },
                    },
                    RESET: {
                        on: {
                            RESET_ACTIONED: {
                                target: WidgetStates.IDLE,
                            },
                        },
                    },
                    DESTROYING: {
                        on: {
                            DESTROYED: WidgetStates.DESTROYED,
                            REFRESH_TRIGGERED: undefined,
                        },
                    },
                    DESTROYED: {
                        entry: "horizonDeleteWidget",
                        type: "final",
                    },
                    ERRORED: {
                        on: {
                            REFRESH_TRIGGERED: undefined,
                        },
                    },
                },
            },
            {
                guards: {
                    canPauseUpdates: (context) => {
                        if (context.widgetData === null) {
                            return false;
                        }

                        return context.widgetData.canPauseUpdates === true;
                    },
                    canHorizonTriggerUpdate: (context) => {
                        if (context.widgetData === null) {
                            return false;
                        }

                        return (
                            context.widgetData.canHorizonTriggerUpdate === true
                        );
                    },
                    canUpdate: (context) => {
                        return (
                            context.isAscxLoaded === true &&
                            context.isUpdatePending === true
                        );
                    },
                    canHorizonTriggerNewUpdate: (context) => {
                        if (context.widgetData === null) {
                            return false;
                        }

                        return (
                            context.widgetData.canHorizonTriggerUpdate ===
                                true &&
                            context.isHydrated &&
                            context.isUpdatePending !== true
                        );
                    },
                    canTriggerNewUpdate: (context) => {
                        return (
                            context.isHydrated &&
                            context.isUpdatePending !== true
                        );
                    },
                    didSetupComplete: (context) => {
                        return context.isHydrated === true;
                    },
                    initialisationComplete: (context) => {
                        return (
                            context.isAscxLoaded === true &&
                            context.isWidgetCreated === true &&
                            (!isWidgetsUsingSettingsMachine(
                                context.widgetName
                            ) ||
                                (context.instanceState !== null &&
                                    context.settingsMachine.state.context.settingsComponents.every(
                                        (x) => x.isValid !== null
                                    )))
                        );
                    },
                    settingsLoadedAndAnyInvalid: (context) => {
                        return (
                            isWidgetsUsingSettingsMachine(context.widgetName) &&
                            context.instanceState !== null &&
                            context.settingsMachine.state.context.settingsComponents.every(
                                (x) => x.isValid !== null
                            ) &&
                            context.settingsMachine.state.context.settingsComponents.some(
                                (x) => x.isValid === false
                            )
                        );
                    },
                    isPausedOnInit: (context) => {
                        return context.containerData.isPaused === true;
                    },
                    dataSavedFromComponents: (context) => {
                        if (Object.keys(context.transientDataIds).length > 0) {
                            return (
                                Object.keys(context.transientDataIds).length ===
                                Object.keys(context.transientData).length
                            );
                        }
                        return false;
                    },
                    noDataSavedFromComponents: (context) => {
                        return Object.keys(context.transientData).length === 0;
                    },
                    noDataSavedFromComponentsAndStateBeforeRefreshSettingsOpen:
                        (context) => {
                            return (
                                Object.keys(context.transientData).length ===
                                    0 &&
                                context.stateWhenRefreshed ===
                                    WidgetStates.SETTINGS_OPEN
                            );
                        },
                    noDataSavedFromComponentsAndStateBeforeRefreshPaused: (
                        context
                    ) => {
                        return (
                            Object.keys(context.transientData).length === 0 &&
                            context.stateWhenRefreshed === WidgetStates.PAUSED
                        );
                    },
                    didSetupCompleteAndStateBeforeRefreshSettingsOpen: (
                        context
                    ) => {
                        return (
                            context.isHydrated === true &&
                            context.stateWhenRefreshed ===
                                WidgetStates.SETTINGS_OPEN
                        );
                    },
                    didSetupCompleteAndStateBeforeRefreshPaused: (context) => {
                        return (
                            context.isHydrated === true &&
                            context.stateWhenRefreshed === WidgetStates.PAUSED
                        );
                    },
                    isListeningForEventFromThisSource: (context, event) => {
                        if (
                            context.widgetData.listenToEventsFrom ===
                                undefined ||
                            event.data.Source === undefined
                        ) {
                            return false;
                        }
                        return context.widgetData.listenToEventsFrom.includes(
                            event.data.Source
                        );
                    },
                    isEventMessageTypeRefreshData: (context, event) => {
                        return event.data.Type === 1; // Website\Platform\AcisHorizon.Widget.Framework\WidgetEventArgs.cs -> public enum MessageType
                    },
                    canUpdateInstanceState: (context, event) => {
                        // State hasn't been loaded yet so it can't be Updated as it might break the data stored in the database
                        if (context.instanceState === null) {
                            return false;
                        }
                        // Widgets are adjusted on the fly to support Export or Print when opened in new pages and we don't want to save those changes
                        if (context.PageType === PageTypes.TRANSIENT) {
                            return false;
                        }
                        const updatedInstanceState = ObjectUtils.mergeDeep(
                            context.instanceState,
                            event.instanceState,
                            instanceStateArrayMergeStrategy
                        );

                        if (
                            JSON.stringify(context.instanceState) ===
                            JSON.stringify(updatedInstanceState)
                        ) {
                            return false; // no change
                        }

                        return true;
                    },
                },
                actions: {
                    horizonRegisterUpdateFunction: (context) => {
                        // Register the update function with MyFramework2
                        widgetUpdate.registerWidget(
                            context.widgetId,
                            `Horizon.horizonTriggeredUpdate(${context.widgetId});`,
                            {}
                        );
                    },
                    horizonNotifyUpdateComplete: () => {
                        // Clean up triggers and send complete notification to MyFramework2
                        widgetUpdate.updateComplete();
                    },
                    horizonDeleteWidget: (context) => {
                        AcisHorizon.Web.Framework.WidgetService.DeleteWidgetInstance(
                            context.widgetId
                        );
                    },
                    horizonPauseWidgetUpdates: (context) => {
                        AcisHorizon.Web.Framework.WidgetService.PauseUpdatesWidgetInstance(
                            context.widgetId
                        );
                    },
                    horizonResumeWidgetUpdates: (context) => {
                        AcisHorizon.Web.Framework.WidgetService.ResumeUpdatesWidgetInstance(
                            context.widgetId
                        );
                    },
                    widgetUpdateContainerName: (context, event) => {
                        AcisHorizon.Web.Framework.WidgetService.ChangeWidgetInstanceTitle(
                            context.widgetId,
                            event.newName
                        );
                    },
                    instanceStateSave: (context) => {
                        AcisHorizon.Web.Framework.WidgetService.SaveWidgetState(
                            context.widgetId,
                            X2j.js2xml(
                                { state: { ...context.instanceState } },
                                {
                                    compact: true,
                                }
                            ),
                            () => {
                                // all good"
                            },
                            (data) => {
                                // This feels pretty bad but can't find a way around it. If this action is launched as an Actor Callback it functions but the callback part upsets the Vuex store as it is considered mutating state.
                                // If/when we drop Vuex this should be extracted to an Actor and the event being fired here should be sendParent or similar
                                horizonStore.commit(
                                    `widget${context.widgetId}/sendEvent`,
                                    {
                                        type: WidgetEvents.ERROR,
                                        errorType:
                                            HorizonErrors.GETTING_DATA_FROM_PLATFORM,
                                        dotNetError: data,
                                    }
                                );
                            }
                        );
                    },
                },
            }
        );
    },
};
