<template>
    <div class="vue-content-error-message">
        {{ $t(errorType) }}
    </div>
</template>

<script>
import * as EnglishStrings from "../../localisation/errors.en.json";
import * as FrenchStrings from "../../localisation/errors.fr.json";

export default {
    name: "Error",
    props: {
        errorType: {
            type: String,
            required: true,
        },
    },
    i18n: {
        messages: {
            en: EnglishStrings,
            fr: FrenchStrings,
        },
    },
};
</script>

<style lang="scss" scoped>
.vue-content-error-message {
    color: var(--vue-widget-error-highlight);
    font-size: 20px;
    margin: auto;
    padding: 10px;
    text-align: center;
    z-index: 101;
}
</style>
