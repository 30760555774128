var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.isValid ? false : "errors" },
    [
      _c("ModalWrapper", {
        attrs: {
          type: _vm.type,
          "modal-customisation": _vm.modalCustomisation,
          "is-valid": _vm.isValid,
          "validation-messages": _vm.validationMessages,
        },
        on: {
          "modal-closed": _vm.handleModalClosed,
          "validation-pass": _vm.handleValidationPass,
          "validation-fail": _vm.handleValidationFail,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }