<template>
    <span class="vue-widget-header-text vue-widget-header-drag">
        <div v-if="isEditing" class="vue-widget-header-text-edit-font-size">
            <input v-model="newDisplayName" class="input-width-widget-name" />
            <button
                class="vue-widget-button-highlight vue-widget-header-button-obfuscate"
                @click.prevent="handleSaveNewName"
            >
                {{ $t("save") }}
            </button>
            <button
                class="vue-widget-button-highlight vue-widget-header-button-obfuscate"
                @click.prevent="isEditing = false"
            >
                {{ $t("cancel") }}
            </button>
        </div>
        <div v-else>
            <button
                class="vue-widget-button-highlight vue-widget-header-button-obfuscate"
                @click.prevent="isEditing = true"
            >
                {{ displayName }}
            </button>
            <span
                v-show="showDataConnection"
                class="vue-widget-data-connection"
            >
                - {{ getDataConnectionDisplayText }}
            </span>
            <span v-show="isPaused" class="vue-widget-time-paused">
                ({{ pauseTime }})
            </span>
        </div>
    </span>
</template>

<script>
import * as WidgetEvents from "../../constants/widgetEvents";
import * as WidgetStates from "../../constants/widgetStates";
import { getOffsetTime } from "../../../scripts/utils/dateTimeUtils";
import * as EnglishStrings from "../../localisation/buttons.en.json";
import * as FrenchStrings from "../../localisation/buttons.fr.json";

export default {
    name: "WidgetHeaderText",
    props: {
        widgetId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            isEditing: false,
            pauseTime: "",
            displayName: "",
            newDisplayName: "",
        };
    },
    computed: {
        containerName() {
            return this.$store.getters[`widget${this.widgetId}/containerName`];
        },
        isPaused() {
            return (
                this.$store.state[`widget${this.widgetId}`].currentState ===
                WidgetStates.PAUSED
            );
        },
        horizonSystemTimezoneOffsetInMs() {
            return this.$store.getters.getHorizonSystemTimezoneOffsetInMs;
        },
        showDataConnection() {
            return this.getDataConnectionDisplayText !== "";
        },
        getDataConnectionDisplayText() {
            const uc =
                this.$store.state[`widget${this.widgetId}`].context.header
                    .userConnectionTitleText ?? "";
            const int =
                this.$store.state[`widget${this.widgetId}`].context.header
                    .interchangeName ?? "";

            let sep = "";
            if (uc !== "" && int !== "") {
                sep = "|";
            }
            return `${uc}${sep}${int}`;
        },
        horizonLanguage() {
            return this.$store.state.context.horizonData.languageCode;
        },
        timeFormatter() {
            return new Intl.DateTimeFormat(this.horizonLanguage, {
                timeStyle: "short",
            });
        },
    },
    watch: {
        containerName: {
            immediate: true,
            handler(containerName) {
                this.displayName = containerName;
            },
        },
        isEditing(isEditing) {
            if (!isEditing) {
                return;
            }
            this.newDisplayName = this.displayName;
        },
        isPaused: {
            immediate: true,
            handler(isPaused) {
                if (!isPaused) {
                    this.pauseTime = "";
                    return;
                }
                const currentTime = getOffsetTime(
                    this.horizonSystemTimezoneOffsetInMs
                );

                this.pauseTime = this.timeFormatter.format(currentTime);
            },
        },
    },
    methods: {
        handleSaveNewName() {
            this.displayName = this.newDisplayName.trim();
            this.$store.commit(`widget${this.widgetId}/sendEvent`, {
                type: WidgetEvents.CONTAINER_RENAMED,
                newName: this.displayName,
            });
            this.isEditing = false;
        },
    },
    i18n: {
        messages: {
            en: EnglishStrings,
            fr: FrenchStrings,
        },
    },
};
</script>

<style lang="scss" scoped>
.vue-widget-header-text {
    color: var(--skin-primary-colour);
    font-size: 10pt;
    font-weight: bold;
    margin-block-end: auto;
    margin-block-start: auto;
    padding-left: 2px;
    padding-right: 0px;
    text-align: left;
}

.vue-widget-header-drag {
    cursor: move;
    flex: 1;
}

.vue-widget-button-highlight {
    &:hover {
        cursor: pointer;
    }
}

.vue-widget-header-button-obfuscate {
    background: inherit;
    border: 0;
    color: inherit;
    font: inherit;
    padding: 0px;
}

.vue-widget-header-text-edit-font-size {
    font-size: 8pt;
}

.input-width-widget-name {
    width: var(--input-width-widget-name);
}
</style>
