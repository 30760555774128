var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "modal" } }, [
    _c("div", { staticClass: "modal-mask" }, [
      _c(
        "div",
        {
          staticClass: "modal-wrapper",
          attrs: {
            role: "dialog",
            "aria-labelledby": "modalTitle",
            "aria-describedby": "modalDescription",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-container",
              class: [{ "modal-default-style": _vm.useDefaultStyle }],
            },
            [
              _c(
                _vm.modalComponentFromType,
                _vm._g(
                  {
                    tag: "component",
                    attrs: {
                      "is-valid": _vm.isValid,
                      "validation-messages": _vm.validationMessages,
                      "modal-customisation": _vm.modalCustomisation,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _c("h3", [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.hasCustomHeader
                                      ? _vm.modalCustomisation.text.header
                                      : _vm.$t(`${_vm.type}.header`)
                                  ) +
                                  "\n                        "
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "body",
                        fn: function () {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.hasCustomBody
                                    ? _vm.modalCustomisation.text.body
                                    : _vm.$t(`${_vm.type}.body`)
                                )
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "serverValidationResponse",
                        fn: function () {
                          return [
                            _vm.isValid === false &&
                            _vm.hasServerValidationResponseMessage
                              ? _c(
                                  "span",
                                  { staticClass: "validation-error-text" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.modalCustomisation.validation
                                          .serverValidation.messageToDisplay
                                      )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  _vm.$listeners
                )
              ),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }