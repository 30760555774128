var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "horizonApp" } },
    [
      _c("GetSignCollections"),
      _vm._v(" "),
      _c("GetOccupancySettings"),
      _vm._v(" "),
      _vm._l(_vm.instances, function (instance) {
        return _c("WidgetInstance", {
          key: instance.key,
          attrs: {
            "widget-id": instance.widgetId,
            "widget-name": instance.widgetName,
          },
          on: { "destroy-widget": _vm.destroyWidgetInstance },
        })
      }),
      _vm._v(" "),
      _vm.modal.show
        ? _c("ModalManager", {
            attrs: {
              type: _vm.modal.data.modalType,
              "modal-customisation": _vm.modal.data,
            },
            on: { "modal-closed": _vm.handleModalClosed },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }