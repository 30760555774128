<template>
    <GetUserSettings
        :get="get"
        :group="group"
        @settings-loaded="handleSettingsLoaded"
        @error="handleError"
    />
</template>

<script>
import GetUserSettings from "./GetUserSettings.vue";
import * as HorizonEvents from "../../../constants/horizonEvents";
import * as DataLoadingStates from "../../../constants/dataLoadingStates";
import * as HorizonUserStateGroups from "../../../constants/horizonUserStateGroups";

export default {
    name: "GetOccupancySettings",
    components: {
        GetUserSettings,
    },
    data() {
        return {
            get: false,
            group: HorizonUserStateGroups.OCCUPANCY_MANAGER_SETTINGS,
            numberOfBands: 4,
            defaultBandValues: {
                key: -1,
                isEnabled: false,
                colour: "#cccccc",
                // using an object to allow binding the value as a number rather than a string
                mins: { number: 0 },
                secs: { number: 0 },
                timeInSeconds: function () {
                    return this.isEnabled
                        ? this.mins.number * 60 + this.secs.number
                        : -1;
                },
            },
        };
    },
    computed: {
        isLoadOccupancySettingsRequired() {
            return (
                this.$store.state.context.occupancySettingsLoadState ===
                DataLoadingStates.LOAD
            );
        },
    },
    watch: {
        isLoadOccupancySettingsRequired: {
            immediate: true,
            handler: function (isLoadOccupancySettingsRequired) {
                this.get = isLoadOccupancySettingsRequired;
            },
        },
    },
    methods: {
        handleSettingsLoaded(settings) {
            const settingsFormatted = {
                bands: [],
                defaultBandValues: this.defaultBandValues,
                noOccupancyColour: settings.occupancyNoClashColor._text,
                numberOfBands: this.numberOfBands,
            };

            for (let i = 0; i < this.numberOfBands; i++) {
                const timeInSeconds = Number.parseInt(
                    settings[`occupancy${i}Time`]._text,
                    10
                );
                if (Number.isNaN(timeInSeconds)) {
                    this.$store.commit(
                        "sendEvent",
                        HorizonEvents.OCCUPANCY_SETTINGS_ERROR
                    );
                    return;
                }

                const isEnabled = timeInSeconds >= 0;
                settingsFormatted.bands.push({
                    ...this.defaultBandValues,
                    key: i,
                    mins: { number: Math.floor(timeInSeconds / 60) },
                    secs: { number: timeInSeconds % 60 },
                    colour: isEnabled
                        ? settings[`occupancy${i}Color`]._text
                        : this.defaultBandValues.colour,
                    isEnabled: isEnabled,
                });
            }

            this.$store.commit("sendEvent", {
                type: HorizonEvents.OCCUPANCY_SETTINGS_SET_CONTEXT,
                occupancySettings: settingsFormatted,
            });
        },
        handleError() {
            this.$store.commit(
                "sendEvent",
                HorizonEvents.OCCUPANCY_SETTINGS_ERROR
            );
        },
    },
};
</script>
