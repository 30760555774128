import Vue from "vue";
import Vuex from "vuex";
import { interpret } from "xstate";
import * as X2j from "xml-js";
import * as LogLevels from "../constants/logLevels";
import * as HorizonEvents from "../constants/horizonEvents";
import * as HorizonStates from "../constants/horizonStates";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
    strict: debug,
    state() {
        return {
            horizonStateMachineService: null,
            currentState: null,
            context: null,
        };
    },
    mutations: {
        sendEvent(state, event) {
            state.horizonStateMachineService.send(event);
        },
        initialiseStateMachine(state, machine) {
            state.horizonStateMachineService = interpret(machine)
                .onTransition((newState) => {
                    if (debug) {
                        console.log(
                            `%cHORIZON: %c${newState._event.name} %c| %c${state.currentState} %c-> %c${newState.value}`,
                            LogLevels.LogLevelColours[LogLevels.MISC],
                            LogLevels.LogLevelColours[LogLevels.WARNING],
                            LogLevels.LogLevelColours[LogLevels.MISC],
                            LogLevels.LogLevelColours[LogLevels.INFO],
                            LogLevels.LogLevelColours[LogLevels.MISC],
                            LogLevels.LogLevelColours[LogLevels.WARNING]
                        );
                    }
                    state.currentState = newState.value;
                    state.context = newState.context;
                })
                .start();
        },
    },
    actions: {
        loadRoleRight(context, roleRight) {
            if (
                !Object.hasOwnProperty.call(
                    context.state.context.roleRights,
                    roleRight
                )
            ) {
                context.commit("sendEvent", {
                    type: HorizonEvents.ROLE_RIGHT_LOADING,
                    roleRight,
                });
                AcisHorizon.Web.Framework.WidgetService.DoesUserHaveRoleRight(
                    roleRight,
                    (hasRoleRight) => {
                        context.commit("sendEvent", {
                            type: HorizonEvents.ROLE_RIGHT_SET,
                            roleRight,
                            hasRoleRight,
                        });
                    },
                    // TODO Failure
                    () => {}
                );
            }
        },
        saveUserSettingsElements(context, payload) {
            AcisHorizon.Web.Framework.WidgetService.SetUserSettingsElements(
                payload.group,
                X2j.js2xml(payload.settings, {
                    compact: true,
                }),
                () => {
                    context.commit("sendEvent", payload.onSuccessEvent);
                },
                () => {
                    context.commit("sendEvent", payload.onFailureEvent);
                }
            );
        },
        resetUserSettingsElements(context, payload) {
            AcisHorizon.Web.Framework.WidgetService.DeleteUserSettingsForGroup(
                payload.group,
                () => {
                    context.commit("sendEvent", payload.onSuccessEvent);
                },
                () => {
                    context.commit("sendEvent", payload.onFailureEvent);
                }
            );
        },
    },
    getters: {
        getFirstTaskInQueue: (state) => {
            if (state.currentState !== HorizonStates.QUEUE_RUN) {
                return null;
            }

            if (state.context.queue.length === 0) {
                return null;
            }

            return state.context.queue[0];
        },
        getHorizonSystemTimezoneOffsetInMs: (state) => {
            return state.context.horizonData.horizonSystemTimezoneOffsetInMs;
        },
        getRoleRight: (state) => (roleRightName) => {
            return Object.hasOwnProperty.call(
                state.context.roleRights,
                roleRightName
            )
                ? state.context.roleRights[roleRightName]
                : null;
        },
        availableInterchangeIds: (state) => {
            return state.context.signCollections
                .filter((sc) => {
                    return sc.IsInterchange;
                })
                .map((sc) => {
                    return sc.Pk_SignCollectionId;
                });
        },
        getInterchange: (state) => (interchangeId) => {
            const interchange = state.context.signCollections.filter((sc) => {
                return sc.Pk_SignCollectionId === interchangeId;
            });
            return interchange.length === 0 ? null : interchange[0];
        },
    },
});
