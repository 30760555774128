import { createMachine, assign } from "xstate";
import * as HorizonStates from "../constants/horizonStates";
import * as DataLoadingStates from "../constants/dataLoadingStates";
import * as LogLevels from "../constants/logLevels";

const debug = process.env.NODE_ENV !== "production";

// horizonData is setup in Context.aspx.cs
export default {
    horizonStateMachineConstructor(horizonData) {
        return createMachine(
            {
                id: `horizon`,
                context: {
                    debug: {
                        isDebug: debug,
                    },
                    horizonData,
                    queue: [],
                    roleRights: {},

                    signCollectionsLoadState: DataLoadingStates.NOT_REQUESTED,
                    signCollections: [],

                    occupancySettingsLoadState: DataLoadingStates.NOT_REQUESTED,
                    occupancySettings: null,
                },
                initial: HorizonStates.PLATFORM_INITIALISING,
                on: {
                    UPDATE_DATA: {
                        actions: assign({
                            horizonData: (context, event) => ({
                                ...event.horizonData,
                                ...context.horizonData,
                            }),
                        }),
                    },
                    QUEUE_ADD: {
                        actions: assign({
                            queue: (context, event) => {
                                const updateQueue = context.queue;
                                updateQueue.push({
                                    ...event.task,
                                    id:
                                        Math.random()
                                            .toString(36)
                                            .substring(2, 15) +
                                        Math.random()
                                            .toString(36)
                                            .substring(2, 15),
                                });
                                return updateQueue;
                            },
                        }),
                    },
                    QUEUE_TASK_COMPLETE: {
                        actions: assign({
                            queue: (context, event) => {
                                let updateQueue = context.queue;

                                updateQueue = updateQueue.filter((task) => {
                                    return task.id !== event.taskId;
                                });
                                return updateQueue;
                            },
                        }),
                    },
                    ROLE_RIGHT_LOADING: {
                        actions: assign({
                            roleRights: (context, event) => {
                                const roleRights = context.roleRights;
                                roleRights[event.roleRight] = null;
                                return roleRights;
                            },
                        }),
                    },
                    ROLE_RIGHT_SET: {
                        actions: assign({
                            roleRights: (context, event) => {
                                const roleRights = context.roleRights;
                                roleRights[event.roleRight] =
                                    event.hasRoleRight;
                                return roleRights;
                            },
                        }),
                    },
                    SIGN_COLLECTIONS_GET: {
                        actions: assign({
                            signCollectionsLoadState: DataLoadingStates.LOAD,
                        }),
                        cond: "signCollectionsNotLoaded",
                    },
                    SIGN_COLLECTIONS_SET_CONTEXT: {
                        actions: assign({
                            signCollections: (context, event) =>
                                event.signCollections,
                            signCollectionsLoadState: DataLoadingStates.LOADED,
                        }),
                    },
                    SIGN_COLLECTIONS_ERROR: {
                        actions: assign({
                            signCollectionsLoadState: DataLoadingStates.ERROR,
                        }),
                    },
                    OCCUPANCY_SETTINGS_GET: {
                        actions: assign({
                            occupancySettingsLoadState: DataLoadingStates.LOAD,
                        }),
                        cond: "occupancySettingsNotLoaded",
                    },
                    OCCUPANCY_SETTINGS_SET_CONTEXT: {
                        actions: assign({
                            occupancySettings: (context, event) =>
                                event.occupancySettings,
                            occupancySettingsLoadState:
                                DataLoadingStates.LOADED,
                        }),
                    },
                    OCCUPANCY_SETTINGS_SET_DB: {
                        actions: assign({
                            occupancySettingsLoadState: DataLoadingStates.LOAD,
                        }),
                    },
                    OCCUPANCY_SETTINGS_ERROR: {
                        actions: assign({
                            occupancySettingsLoadState: DataLoadingStates.ERROR,
                        }),
                    },
                    LOG: {
                        actions: "debugLog",
                    },
                },
                states: {
                    PLATFORM_INITIALISING: {
                        on: {
                            HAS_MOUNTED: HorizonStates.PLATFORM_IDLE,
                        },
                    },
                    PLATFORM_IDLE: {
                        always: {
                            target: HorizonStates.QUEUE_RUN,
                            cond: "queueHasTasks",
                        },
                    },
                    QUEUE_RUN: {
                        always: {
                            target: HorizonStates.PLATFORM_IDLE,
                            cond: "queueIsEmpty",
                        },
                        on: {
                            POPUP_WIDGET_OPEN: {
                                actions: "showPopupWidget",
                            },
                            HELP_OPEN: {
                                actions: "showHelp",
                            },
                        },
                    },
                },
            },
            {
                guards: {
                    queueHasTasks: (context) => {
                        return context.queue.length > 0;
                    },
                    queueIsEmpty: (context) => {
                        return context.queue.length === 0;
                    },
                    occupancySettingsNotLoaded: (context) => {
                        return (
                            context.occupancySettingsLoadState ===
                            DataLoadingStates.NOT_REQUESTED
                        );
                    },
                    signCollectionsNotLoaded: (context) => {
                        return (
                            context.signCollectionsLoadState ===
                            DataLoadingStates.NOT_REQUESTED
                        );
                    },
                },
                actions: {
                    showHelp: (context, event) => {
                        WebHelp.show(event.helpUrl);
                    },
                    showPopupWidget: (context, event) => {
                        PopupWidget.show(event.key);
                    },
                    debugLog: (context, event) => {
                        if (!debug) {
                            return;
                        }
                        if (
                            Object.prototype.hasOwnProperty.call(
                                event.data,
                                "text"
                            )
                        ) {
                            console.log(
                                `%c${event.data.text}`,
                                LogLevels.LogLevelColours[event.data.level]
                            );
                        } else {
                            console.log(event.data);
                        }
                    },
                },
            }
        );
    },
};
