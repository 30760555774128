var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vue-content-error-message" }, [
    _vm._v("\n    " + _vm._s(_vm.$t(_vm.errorType)) + "\n"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }