<template>
    <div
        :id="'widgetDisplayRoot' + widgetId + widgetName"
        class="vue-widget-display-root"
        :class="[
            { 'vue-widget-maximised': isMaximised },
            { 'vue-widget-error': inError },
            { 'vue-widget-attention': widgetRequiresAttention },
        ]"
    >
        <AmBusy v-if="isBusy" />
        <WidgetHeader
            :id="'widgetHeader' + widgetId"
            :colour-gradient1="headerColourGradient1"
            :colour-gradient2="headerColourGradient2"
        >
            <template #content>
                <WidgetHeaderText :widget-id="widgetId" />
                <img
                    v-show="isUpdating"
                    src="/dist/images/General/progress-bar.gif"
                    alt="update indicator"
                    class="vue-widget-update-progress"
                />
                <WidgetHeaderButtons
                    :widget-id="widgetId"
                    @save-location-info="saveLocationInfo"
                />
            </template>
        </WidgetHeader>
        <WidgetDebug v-if="showDebug" :widget-id="widgetId" />
        <WidgetContent
            v-show="!isMinimised"
            :widget-id="widgetId"
            :widget-name="widgetName"
            @widget-requires-attention="widgetRequiresAttention = $event"
        />
    </div>
</template>

<script>
import * as WidgetEvents from "../../constants/widgetEvents";
import * as ModalTypes from "../../constants/modalTypes";
import * as WidgetDisplayStates from "../../constants/widgetDisplayStates";
import * as WidgetStates from "../../constants/widgetStates";
import * as HorizonRoleRights from "../../constants/horizonRoleRights";
import AmBusy from "../utils/AmBusy.vue";
import WidgetDebug from "./WidgetDebug.vue";
import WidgetContent from "./WidgetContent.vue";
import WidgetHeader from "./WidgetHeader.vue";
import WidgetHeaderButtons from "./WidgetHeaderButtons.vue";
import WidgetHeaderText from "./WidgetHeaderText.vue";

export default {
    name: "WidgetDisplayRoot",
    components: {
        AmBusy,
        WidgetDebug,
        WidgetContent,
        WidgetHeader,
        WidgetHeaderButtons,
        WidgetHeaderText,
    },
    props: {
        widgetId: {
            type: Number,
            required: true,
        },
        widgetName: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            widgetRequiresAttention: false,
        };
    },
    computed: {
        isBusy() {
            return this.$store.getters[`widget${this.widgetId}/isBusy`];
        },
        showDebug() {
            const showDebug = this.$store.getters.getRoleRight(
                HorizonRoleRights.VIEW_DEBUG
            );
            if (showDebug === null) {
                this.$store.dispatch(
                    "loadRoleRight",
                    HorizonRoleRights.VIEW_DEBUG
                );
            }
            return showDebug;
        },
        currentState() {
            return this.$store.state[`widget${this.widgetId}`].currentState;
        },
        inError() {
            return this.currentState === WidgetStates.ERRORED;
        },
        displayState() {
            return this.$store.state[`widget${this.widgetId}`].context
                .displayMachine.state.value;
        },
        isMaximised() {
            return this.displayState === WidgetDisplayStates.MAXIMISED;
        },
        isMinimised() {
            return this.displayState === WidgetDisplayStates.MINIMISED;
        },
        isUpdating() {
            return this.currentState === WidgetStates.UPDATING_DATA;
        },
        isUpdateComplete() {
            return this.currentState === WidgetStates.UPDATE_COMPLETED;
        },
        isDestroying() {
            return this.currentState === WidgetStates.DESTROYING;
        },
        modalResult() {
            return this.$store.getters[`widget${this.widgetId}/modalResult`];
        },
        widgetContainerElementId() {
            return this.$store.state[`widget${this.widgetId}`].context
                .containerData.location.containerId;
        },
        zoneIndex() {
            return this.$store.state[`widget${this.widgetId}`].context
                .containerData.location.zoneIndex;
        },
        previousZoneId() {
            return this.$store.state[`widget${this.widgetId}`].context
                .displayMachine.state.context.previousZoneId;
        },
        previousPrecedingSiblingId() {
            return this.$store.state[`widget${this.widgetId}`].context
                .displayMachine.state.context.previousPrecedingSiblingId;
        },
        isPrinting() {
            return (
                this.$store.state[`widget${this.widgetId}`].context
                    .containerData.isPrinting &&
                this.currentState === WidgetStates.IDLE
            );
        },
        headerColourGradient1() {
            if (this.inError) {
                return "var(--vue-widget-error-lowlight)";
            }
            if (this.widgetRequiresAttention) {
                return "var(--vue-widget-attention-lowlight)";
            }
            return "var(--skin-primary-background)";
        },
        headerColourGradient2() {
            if (this.inError) {
                return "var(--vue-widget-error-highlight)";
            }
            if (this.widgetRequiresAttention) {
                return "var(--vue-widget-attention-highlight)";
            }
            return "var(--skin-primary-background-gradient)";
        },
    },
    watch: {
        displayState(displayState) {
            switch (displayState) {
                case WidgetDisplayStates.MAXIMISING:
                    this.moveContainerToMaximise();
                    this.$store.commit(
                        `widget${this.widgetId}/sendEvent`,
                        WidgetEvents.DISPLAY_VISUAL_CHANGE_COMPLETE
                    );
                    break;
                case WidgetDisplayStates.REVERTING_FROM_MAX_FROM_WIDGET_TRIGGER:
                case WidgetDisplayStates.REVERTING_FROM_MAX_FROM_HORIZON_TRIGGER: {
                    const previousPrecedingSibling =
                        this.previousPrecedingSiblingId;
                    if (previousPrecedingSibling !== null) {
                        document
                            .getElementById(previousPrecedingSibling)
                            .insertAdjacentElement(
                                "afterend",
                                document.getElementById(
                                    this.widgetContainerElementId
                                )
                            );
                    } else {
                        const previouslyOccupiedZone = document.getElementById(
                            this.previousZoneId
                        );
                        if (
                            this.zoneIndex >
                            previouslyOccupiedZone.children.length
                        ) {
                            previouslyOccupiedZone.append(
                                document.getElementById(
                                    this.widgetContainerElementId
                                )
                            );
                        } else {
                            previouslyOccupiedZone.prepend(
                                document.getElementById(
                                    this.widgetContainerElementId
                                )
                            );
                        }
                    }

                    this.$store.commit(
                        `widget${this.widgetId}/sendEvent`,
                        WidgetEvents.DISPLAY_VISUAL_CHANGE_COMPLETE
                    );
                    break;
                }
                default:
                    break;
            }
        },
        modalResult(modalResult) {
            if (modalResult === null) {
                return;
            }
            switch (modalResult.modalType) {
                case ModalTypes.DELETE_WIDGET:
                    if (modalResult.modal.result) {
                        this.$store.commit(
                            `widget${this.widgetId}/sendEvent`,
                            WidgetEvents.DESTROY
                        );
                    }
                    this.$store.commit(`widget${this.widgetId}/sendEvent`, {
                        type: WidgetEvents.MODAL_RESULT_RETRIEVED,
                    });
                    break;
                case ModalTypes.RESET_SETTINGS:
                    if (modalResult.modal.result) {
                        // two events to support legacy and StateMachine settings approach
                        this.$store.commit(
                            `widget${this.widgetId}/sendEvent`,
                            WidgetEvents.RESET_TRIGGERED
                        );
                        this.$store.commit(
                            `widget${this.widgetId}/sendEvent`,
                            WidgetEvents.SETTINGS_MACHINE_RESET_TRIGGERED
                        );
                    }
                    this.$store.commit(`widget${this.widgetId}/sendEvent`, {
                        type: WidgetEvents.MODAL_RESULT_RETRIEVED,
                    });
                    break;
                default:
                    break;
            }
        },
        isUpdateComplete(isUpdateComplete) {
            if (isUpdateComplete) {
                this.$store.commit(
                    `widget${this.widgetId}/sendEvent`,
                    WidgetEvents.UPDATE_RESET
                );
            }
        },
        isPrinting: {
            immediate: true,
            handler(isPrinting) {
                if (isPrinting) {
                    setTimeout(() => {
                        window.print();
                        window.close();
                    }, 1000); // Need a little extra time for the UI to catch up with the state logic
                }
            },
        },
    },
    mounted() {
        if (this.isMaximised) {
            this.saveLocationInfo();
            this.moveContainerToMaximise();
        }

        this.$store.commit(
            `widget${this.widgetId}/sendEvent`,
            WidgetEvents.HAS_MOUNTED
        );
    },
    destroyed() {
        // Remove the store, UI & Horizon data for the widget if it is being permanently destroyed
        if (this.isDestroying) {
            document.getElementById(this.widgetContainerElementId).remove();
            this.$store.commit(
                `widget${this.widgetId}/sendEvent`,
                WidgetEvents.DESTROYED
            );
            this.$store.unregisterModule(`widget${this.widgetId}`);
        }
    },
    methods: {
        saveLocationInfo() {
            // Save the location of the widget so it can maximise/restore correctly
            const thisContainer = document.getElementById(
                this.widgetContainerElementId
            );
            this.$store.commit(`widget${this.widgetId}/sendEvent`, {
                type: WidgetEvents.DISPLAY_SAVE_LOCATION_INFO,
                zoneId: thisContainer.parentElement.id,
                precedingSiblingId:
                    thisContainer.previousElementSibling == null
                        ? null
                        : thisContainer.previousElementSibling.id,
            });
        },
        moveContainerToMaximise() {
            document
                .getElementById("widget_area_wrapper")
                .prepend(
                    document.getElementById(this.widgetContainerElementId)
                );
        },
        destroyContainer() {
            this.$destroy();
        },
    },
};
</script>

<style lang="scss" scoped>
.vue-widget-display-root {
    background-color: var(--colour-site-background-one);
    border: solid 1px var(--colour-widget-border);
    border-top-left-radius: var(--radius-border);
    border-top-right-radius: var(--radius-border);
    margin-bottom: 14px;
    position: relative;

    &:hover {
        border: solid 1px var(--colour-widget-hover-border);
    }
}

.vue-widget-header {
    display: flex;
    justify-content: space-between;
}

.vue-widget-maximised {
    margin-right: 15px;
}

.vue-widget-update-progress {
    height: 15px;
    vertical-align: middle;
}

.vue-widget-error {
    border-color: var(--vue-widget-error-lowlight);
}

.vue-widget-error:hover {
    border-color: var(--vue-widget-error-highlight);
}

.vue-widget-attention {
    border-color: var(--vue-widget-attention-lowlight);
}

.vue-widget-attention:hover {
    border-color: var(--vue-widget-attention-highlight);
}
</style>
